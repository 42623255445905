// backrest:"R","P","N": If empty, nothing happens.
// If only "R" is checked all rider backrests and selections are kept.
// If only "P" is checked all passenger backrests and selections are kept.
// If only "N" is checked all rider and passenger backrests and selections are removed.
// Combinations are allowed, if "R & P" is checked nothing happens.
// Combinations are allowed, if "R & N" is checked passenger backrests are removed.
// Combinations are allowed, if "P & N" is checked rider backrests are removed.

import { sceneObj } from './global_vars.js'
import { updateOptionsArray } from './global.js'
import { setURL } from './url.js'

export function removeBackrests() {
	let backrestArray = []

	for (let q = 0; q < sceneObj.myOptionsArray.length; q++) {
		if (sceneObj.myOptionsArray[q].selected == 'yes') {
			if (sceneObj.myOptionsArray[q].backrest != '') {
				backrestArray.push(sceneObj.myOptionsArray[q].backrest);
			}
		}
	}
	// console.log('backrestArray - 2',  JSON.parse(JSON.stringify(backrestArray)))

	if (backrestArray.length == 0) {
		// console.log('No values do nothing')
	}else {
		if (arrayDoesNotInclude(backrestArray, 'R') == true){
			// console.log('Remove Rider Backrest')
			hideShowBackrest('backrest')
		}
		if (arrayDoesNotInclude(backrestArray, 'P') == true){
			// console.log('Remove Passenger Backrest')
			hideShowBackrest('backrest-passenger')
		}
	}
}

function arrayDoesNotInclude(array, string) {
	return !array.includes(string)
}

function hideShowBackrest(string) {
	// console.log('string',string)
	for (let x = 0; x < sceneObj.myOptionsArray.length; x++) {
		if (sceneObj.myOptionsArray[x].selected == 'yes') {
			// console.log('YES')
			if (sceneObj.myOptionsArray[x].partGroup == string) {
				// console.log('NO',sceneObj.myOptionsArray[x].name)
				sceneObj.myOptionsArray[x].selected = 'no'
				// console.log('sceneObj.myOptionsArray[x].id',sceneObj.myOptionsArray[x].id)
				// console.log('sceneObj.myOptionsArray[x].selected',sceneObj.myOptionsArray[x].selected)
				uncheckCheckboxByValue(sceneObj.myOptionsArray[x].id);
			}
		}
	}

	let array = []

	let checkedValues = $('input:checkbox:checked').map(function() {
		array.push(this.value)
	}).get()
	updateOptionsArray(array)
	setURL()
	// plateOptions()
}

function uncheckCheckboxByValue(value) {
	// Select all checkboxes
	const checkboxes = document.querySelectorAll('input[type="checkbox"]');

	// Iterate through all checkboxes
	checkboxes.forEach((checkbox) => {
		// Check if the checkbox's value matches the specified value
		if (checkbox.value === value) {
			// Uncheck the checkbox
			checkbox.checked = false;
		}
	})
}