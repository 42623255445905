import { sceneObj, params } from './global_vars.js'
import { findMaterialPosition, updateMaterial, getTextures } from './textures_loading.js'


export async function updateTexture(activeTexture, activeMaterial) {
	// console.log('activeTexture', activeTexture)
	// console.log('activeMaterial', activeMaterial)
	await findMaterialPosition(1, activeTexture, activeMaterial).then((data) => {
		if (data[2] == 'color') {
			updateMaterial(data[0], data[1], data[2], data[3], '')
		} else {
			getTextures(data[0], data[1], data[2], data[3])
		}
	})
}