// import $ from "jquery/dist/jquery.min.js"
// import api from "@sketchfab/viewer-api/viewer-api.js"

import { getSceneName, getDataBase } from './database.js'
import { help } from './help.js'

$(document).ready(function() {

	help()

	// We need to get the API and getDataBase
	// So at the completion of function ajaxScenesOnLoad() we call initialize()
	// When API ready - sketchfab.js
	// Get getNodes - sketchfab.js
	// startTextures() - textures.js
	// Then getURL - url.js
	// Then buildMenuTextures() - textures.js
	new ClipboardJS('.copy-link')
	// Main function to orchestrate the process
	// Usage example
	getSceneName()
		.then(() => {
			// console.log("Scene name retrieved and processed");
			getDataBase()
		})
		.catch((error) => {
			console.log("Error retrieving scene name:", error.message);
			console.log("Current sceneObj state:");
		});
})