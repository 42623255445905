import { sceneObj } from './global_vars.js'
import { menuHeaderColor } from './global_popular_functions.js'
import { setURL } from './url.js'
import { formatTextTitle } from './global_popular_functions.js'

export function addStuds() {
	if (sceneObj.studOption == 'yes') {

		// console.log('sceneObj.studOption',sceneObj.studOption)
		// console.log('studsURL', sceneObj.stud)

		// is null undefined NaN
		if (typeof sceneObj.stud !== 'undefined' && sceneObj.stud) {
			// It's not undefined, null or NaN
			sceneObj.activeTexture = sceneObj.stud
		}else {
			// It's undefined, null or NaN
			sceneObj.activeTexture = 'none';
		}
		sceneObj.activeMaterial = 'stud'
		hideIf()
		addStudMenu()
		updateStudMenu()
	}
}

function addStudMenu() {
	// console.log('sceneObj.stud',sceneObj.stud)

	$('.configurator').append('<div id="stud">' +
	'<div id="stud-menu-head" class="menu-head" name="stud">' +
	'Studs' +
	'<img class="menu-image" src="">' +
	'<div class="menu-text"></div>'+
	'</div>' +
	'<div id="buttons-studs" class="buttons">' +
	'</div>')

	$(document).on('click', '#stud-menu-head', function() {
		sceneObj.activeMaterial = $(this).parent().attr('id')
		$('.buttons').empty()
		sceneObj.myFormStuds = $('<div id=studs-form></div>')

		sceneObj.myFormStuds.append('<div class="stud-box">' +
			'<div class="option-box-info">' +
				'<p class="stud-info">No Studs</p>' +
				'<input id="noStuds" class="stud-input" type="radio" value="none" name="Studs">' +
			'</div>' +
		'</div>')

		sceneObj.myFormStuds.append('<div class="stud-box">' +
			'<div class="option-box-info">' +
				'<img class="stud-image" src="' + sceneObj.texturesFolder + 'config/chrome_studs.png">' +
				'<p class="stud-info">Chrome Studs</p>' +
				'<input id="chromeStuds" class="stud-input" type="radio" value="chrome_studs" name="Studs">' +
			'</div>' +
		'</div>')

		sceneObj.myFormStuds.append('<div class="stud-box">' +
			'<div class="option-box-info">' +
				'<img class="stud-image" src="' + sceneObj.texturesFolder + 'config/black_studs.png">' +
				'<p class="stud-info">Black Studs</p>' +
				'<input id="blackStuds" class="stud-input" type="radio" value="black_studs" name="Studs">' +
			'</div>' +
		'</div>')

		$('#buttons-studs').append(sceneObj.myFormStuds)

		hideIf()

		$('input[type=radio][name=Studs]').change(function() {
			sceneObj.stud = this.value
			console.log(sceneObj.stud)
			sceneObj.activeTexture = sceneObj.stud
			hideIf()
			setURL()
			updateStudMenu()
		});

		menuHeaderColor()
	})
}

function updateStudMenu() {
	let str = formatTextTitle(sceneObj.activeTexture)
	$('#' + sceneObj.activeMaterial).children('.menu-head').children('.menu-image').attr("src", sceneObj.texturesFolder + 'config/' + sceneObj.activeTexture + '.png')
	$('#' + sceneObj.activeMaterial).children('.menu-head').children('.menu-text').html(str)
}


function hideIf() {
	if ((sceneObj.stud == 'none')||(sceneObj.stud == '')) {
		$("#noStuds").prop("checked", true);
		hideShowStuds('none')
	}
	if (sceneObj.stud == 'chrome_studs') {
		$("#chromeStuds").prop("checked", true);
		hideShowStuds('chrome_stud')
	}
	if (sceneObj.stud == 'black_studs') {
		$("#blackStuds").prop("checked", true);
		hideShowStuds('black_stud')
	}
}


function hideShowStuds(nameOfStudToShow) {
	for (const key of Object.keys(sceneObj.myNodes)) {
	let name = sceneObj.myNodes[key].name
		if (name != undefined) {
			if ((name.includes('chrome_stud'))||(name.includes('black_stud'))){
				sceneObj.sketchfabAPI.hide(key, function(err) {
					if (!err) {
					}
				})
			}
		}
	}
	if (nameOfStudToShow != "none") {
		for (const key of Object.keys(sceneObj.myNodes)) {
		let name = sceneObj.myNodes[key].name
			if (name != undefined) {
				if (name.includes(nameOfStudToShow)) {
					sceneObj.sketchfabAPI.show(key, function(err) {
						if (!err) {
						}
					})
				}
			}
		}
	}
}