export function drawIllustrations() {
	const canvas = document.getElementById("rectangle");
	const ctx = canvas.getContext("2d");

	let myWindowWidth = $(window).width()
	let myWindowHeight = $(window).height()

	let windowWidthNew = 0
	let windowHeightNew = 0

	// make it square
	if (myWindowWidth > myWindowHeight) {
		windowWidthNew = myWindowHeight
		windowHeightNew = myWindowHeight
	}else {
		windowWidthNew = myWindowWidth
		windowHeightNew = myWindowWidth
	}

	// console.log('windowWidthNew 01', windowWidthNew)
	// Small screens need a bigger box
	if (windowWidthNew < 800){
		windowWidthNew = windowWidthNew * 1.2
		windowHeightNew = windowHeightNew * 1.2
	}
	// Small screens need a bigger box
	if (windowWidthNew < 600){
		windowWidthNew = windowWidthNew * 1.5
		windowHeightNew = windowHeightNew * 1.5
	}

	// /1.1 creates a larger box than /1.2
	let shrink = windowWidthNew/1.1

	// (shrink/0.9) lets move the box a little to the left
	let widthFromLeft = (myWindowWidth - (shrink/0.9)) / 2
	let heightFromTop = (myWindowHeight - shrink) / 2

	// console.log('myWindowWidth', myWindowWidth)
	// console.log('myWindowHeight', myWindowHeight)

	// console.log('windowWidthNew', windowWidthNew)
	// console.log('shrink', shrink)
	// console.log('widthFromLeft ', widthFromLeft ) //358
	// console.log('heightFromTop ', heightFromTop )

	canvas.setAttribute('width', myWindowWidth);
	canvas.setAttribute('height', myWindowHeight);

	ctx.fillStyle = "rgba(72, 105, 138, 0.3)";

	ctx.fillRect(widthFromLeft, heightFromTop, shrink, shrink);
 }

 export function eraseIllustrations() {
	 const canvas = document.getElementById("rectangle");
	 const ctx = canvas.getContext("2d");

	 ctx.clearRect(0, 0, canvas.width, canvas.height);
 }