import { sceneObj } from './global_vars.js'

function toLowerCase(str) {
	return str.replace(/(?:^|\s)\w/g, function(match) {
		return match.toLowerCase()
	})
}

export function gotoCameraPosition(index, checkCount = 0, cameraSpeed = sceneObj.cameraSpeed) {
	const str = index.toLowerCase();
	const positionX = sceneObj.myCameraObj[str + 'PositionX'] * sceneObj.myResize;
	const positionY = sceneObj.myCameraObj[str + 'PositionY'] * sceneObj.myResize;
	const positionZ = sceneObj.myCameraObj[str + 'PositionZ'] * sceneObj.myResize;

	const cameraPosition = [positionX, positionY, positionZ];
	const cameraTarget = [
		sceneObj.myCameraObj[str + 'TargetX'],
		sceneObj.myCameraObj[str + 'TargetY'],
		sceneObj.myCameraObj[str + 'TargetZ']
	];

	// console.log(`Setting camera to position: ${cameraPosition}, target: ${cameraTarget}, attempt: ${checkCount}, speed: ${cameraSpeed}`);

	sceneObj.sketchfabAPI.setCameraLookAt(cameraPosition, cameraTarget, cameraSpeed, function (err) {
		if (!err) {
			cameraCheck(index, positionX, checkCount, cameraSpeed);
			// sceneObj.sketchfabAPI.setCameraEasing('easeLinear');
		} else {
			console.error('Error setting camera look at:', err);
		}
	});
}

function cameraCheck(index, expectedPositionX, checkCount, cameraSpeed) {
	sceneObj.sketchfabAPI.setCameraLookAtEndAnimationCallback(function (err) {
		if (!err) {
			sceneObj.sketchfabAPI.getCameraLookAt(function (err, camera) {
				if (!err) {
					//console.log(`Camera current position (attempt ${checkCount}):`, camera.position);
					verifyCameraPosition(index, expectedPositionX, camera.position[0], checkCount, cameraSpeed);
				} else {
					console.error('Error getting camera look at:', err);
				}
			});
		} else {
			console.error('Error in camera animation callback:', err);
		}
	});
}

function verifyCameraPosition(index, expectedX, actualX, checkCount, cameraSpeed) {
	const roundedExpectedX = Math.round((expectedX + Number.EPSILON) * 10000) / 10000;
	const roundedActualX = Math.round((actualX + Number.EPSILON) * 10000) / 10000;

	const difference = Math.abs(roundedExpectedX - roundedActualX);
	const percentageDifference = (difference / Math.abs(roundedExpectedX)) * 100;
	const threshold = 10; // 10% threshold

	// console.log(`Verifying camera position (percentage difference ${percentageDifference.toFixed(2)}%`);

	if (percentageDifference > threshold) {
		// console.log(`NOT THERE! ${percentageDifference.toFixed(2)}%`);
		if (checkCount < 1) { // Retry only once
			gotoCameraPosition(index, checkCount + 1, cameraSpeed * 2);
		} else {
			console.log(`Camera Gave up! ${percentageDifference.toFixed(2)}%`);
		}
	} else {
		// console.log('Camera reached target within threshold.');
	}
}
