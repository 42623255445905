// All functions here are imported only by textures.js & textures_loading.js

import { sceneObj } from './global_vars.js'
import { formatTextTitle, toTitleCase } from './global_popular_functions.js'


export function addThumbs(index) {
	if (sceneObj.menuOpen == index) {
		$('.buttons').empty();
		$('.buttons-active').removeClass('buttons-active');
		$('.menu-head').removeClass('menu-active')
		sceneObj.menuOpen = 100
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").hide();
		}
	}else {
		$('.buttons').empty();
		for (let i = 0; i < sceneObj.orderTexturesGroupArray[1].length; i++) {
			let add = whatTexturesToShow(sceneObj.orderTexturesGroupArray[2][i]);

			// if (sceneObj.orderTexturesGroupArray[1][i] == 'white') {
			// 	console.log(add)
			// 	console.log(sceneObj.orderTexturesGroupArray[1][i])
			// }

			if ((sceneObj.backrest.chrome == 'none') && (sceneObj.orderTexturesGroupArray[1][i] == 'chrome_plate')) {
				add = 'no';
			}
			if (add == 'yes') {
				let materialType = sceneObj.orderTexturesGroupArray[3][i].replaceAll(' ', '-');
				const emptyOptionTerms = ['none', 'remove', 'no options'];
				if ($(`#material-type-${materialType.toLowerCase()}`).length === 0) {
					let options = emptyOptionTerms.includes((materialType || '').toLowerCase()) ? 'Remove' : 'Options';
					let displayMaterialType = emptyOptionTerms.includes((materialType || '').toLowerCase()) ? '' : toTitleCase(materialType);

					$(`#buttons-${index}`).append(`<div id="material-type-${materialType.toLowerCase()}" class="material-type">${displayMaterialType.replaceAll('-', ' ')}${displayMaterialType ? ' ' : ''}${options}</div><div class="fade-line"></div>`);
				}

				$('#buttons-' + index).append(`<div class="thumb active" id="${sceneObj.orderTexturesGroupArray[1][i]}" style="background-image: url('${sceneObj.texturesFolder}textures/thumb/${sceneObj.orderTexturesGroupArray[1][i]}.png')"></div>`);
			}
		}
		sceneObj.menuOpen = index
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").show();
		}
	}
}

export function createToolTipHTML() {
	$('.buttons').on('mouseover', '.thumb', function() {
		if ((sceneObj.activeMaterial != 'stitching') && (sceneObj.activeMaterial != 'logo') && (sceneObj.activeMaterial != 'stitching_assembly') && (sceneObj.activeMaterial != 'heavy_stitching')) {

			let thumbId = $(this).attr('id');
			if (!$(this).attr('data-tippy-content')) { // Check if the tooltip content is already set
				let tempName = formatTextTitle(thumbId);
				let toolTipHTML = `<img src='${sceneObj.texturesFolder}textures/mouseover/${thumbId}.${sceneObj.preferencesObj.mouseoverExtension}'>${tempName}`;
				$(this).attr('data-tippy-content', toolTipHTML);

				if ((sceneObj.touchScreen == false) && (sceneObj.preferencesObj.mouseover != 'no')) {
					tippy(this, {
						content: toolTipHTML,
						allowHTML: true,
						theme: 'material',
						placement: 'left',
					});
				}
			}
		}
	});
}

export function updateTick() {
	// .thumb only holds the tick the texture thumb is the background image.
	$('.thumb').empty()
	$('#' + sceneObj.activeTexture).append('<img src="' + sceneObj.texturesFolder + 'config/tick.png ">')
}

function whatTexturesToShow(texturesGroup) {
	// Ensure texturesGroup is an array
	if (!Array.isArray(texturesGroup)) {
		texturesGroup = texturesGroup.split(/[ ,]+/).filter(Boolean); // Split on commas and spaces, filter out empty strings
	}

	// Get the list of allowed textures for the active material
	const allowed = sceneObj.texturesMatrix[sceneObj.activeMaterial];
	// Check if any provided textures are allowed for the active material
	if (allowed) {
		return texturesGroup.some(texture => allowed.includes(texture)) ? 'yes' : 'no';
	}
	return 'no'; // Return 'no' if the material is not defined
}