import { sceneObj } from './global_vars.js'
import { sortArrays } from './global.js'
import { initialize } from './sketchfab.js'
import { isInvalid } from './global_popular_functions.js'

let logosOrderArray = []

// Function to get database parameters from URL
function getDBParam() {
	return new Promise((resolve) => {
		const url = new URL(window.location.href);
		sceneObj.dbParam = url.searchParams.get('db');
		if (sceneObj.dbParam !== null) {
			const texturesFolderName = url.searchParams.get('cdn');
			sceneObj.texturesFolder = `https://cdn.digitaldraping.com/${texturesFolderName}/3d/img/`;
			sceneObj.consoleLog.db = `CDN: ${texturesFolderName}. The db parameter value is: ${sceneObj.dbParam}`;
		} else {
			sceneObj.consoleLog.db = 'DB param not found, setting to none';
			sceneObj.dbParam = 'none';
		}
		resolve(sceneObj.dbParam);
	});
}

// Function to get the scene name
export async function getSceneName() {
	try {
		// Wait for getDBParam to complete
		await getDBParam();

		// Ensure partGroup is set before making the AJAX call
		if (!sceneObj.partGroup) {
			throw new Error("partGroup is not set");
		}
		const url = `php/get_scene_name.php?dbParam=${sceneObj.dbParam}&partGroup=${sceneObj.partGroup}`;
		const json = await new Promise((resolve, reject) => {
			$.ajax({
				type: "POST",
				url: url,
				dataType: "json",
				success: (data) => {
					console.log("AJAX call succeeded:", data);
					resolve(data);
				},
				error: (jqXHR, textStatus, errorThrown) => {
					console.error("AJAX call failed:", textStatus, errorThrown);
					reject(new Error(`AJAX Error: ${textStatus} - ${errorThrown}`));
				}
			});
		});
		sceneObj.scene = json.mySceneName;
		return json;
	} catch (error) {
		console.error("Error in getSceneName:", error);
		throw error;
	}
}


export function getDataBase() {
	$.when(
		ajaxTexturesOnLoad(),
		ajaxScenesOnLoad(),
		ajaxLogosOnLoad(),
		ajaxTexturesMatrix(),
		ajaxPreferencesOnLoad(),
		ajaxHideOnLoad(),
		ajaxHideShowOnLoad(),
		ajaxAlphaOnLoad(),
		ajaxOptionsOnLoad().then(getCorrectEnvironment),
		ajaxCameraOnLoad(),
		ajaxStaticMaterialsAndTextures(),
		ajaxGetCenterButtonData()
	).done(function() {
		console.log('AJAX Complete')
		initialize()
	})
}
function ajaxTexturesMatrix() {
	$.ajax({
		url: "php/textures_matrix.php?dbParam=" + sceneObj.dbParam,
		method: "GET",
		dataType: "json",
		success: function(data) {
			sceneObj.texturesMatrix = data
			// Use the data as needed
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.error('Error fetching allowed textures:', textStatus, errorThrown);
		}
	});
}

function ajaxTexturesOnLoad() {
	let myDomain = window.location.hostname
	sceneObj.consoleLog.domain = myDomain
	return $.ajax({
		type: "POST",
		url: "php/textures_on_load.php?dbParam=" + sceneObj.dbParam + "&domain="+myDomain,
		dataType: "json",
		success: function(json) {
			sceneObj.everyTexturesAttributesObj = json.everyTexturesAttributesObj
			const textureNameArray = json.textureNameArray
			const textureGroupArray = json.group
			const textureOrderArray = json.order
			const textureTypeArray = json.type
			sceneObj.orderTexturesGroupArray = sortArrays([textureOrderArray, textureNameArray, textureGroupArray, textureTypeArray])
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxScenesOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/scenes_on_load.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene,
		dataType: "json",
		success: function(json) {
			sceneObj.uid = json.myUid
			sceneObj.stitchPattern = json.myStitchPattern
			sceneObj.logo = json.myLogo
			sceneObj.logoKey = json.myLogoKey
			sceneObj.studOption = json.myStudOption
			sceneObj.myTexturesArray = json.myTexturesArray
			sceneObj.myMaterialsArray = json.myMaterialsArray
			sceneObj.activeMaterial = sceneObj.myMaterialsArray[0]
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxLogosOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/logos_on_load.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			sceneObj.logosObj = json.logosObj
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxPreferencesOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/preferences.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			sceneObj.preferencesObj = json
			// is null undefined NaN
			if (typeof sceneObj.preferencesObj.mouseoverExtension !== 'undefined' && sceneObj.preferencesObj.mouseoverExtension) {
			// It's not undefined, null or NaN
			}else {
			// It's undefined, null or NaN
			sceneObj.preferencesObj.mouseoverExtension = 'png'
			}
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxHideOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/hide.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			sceneObj.hideObj = json
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxHideShowOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/hide_show.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			sceneObj.hideShowObj = json
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxAlphaOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/alpha.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			sceneObj.alphaObj = json
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxOptionsOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/options_on_load.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene + "&partGroup=" + sceneObj.partGroup,
		dataType: "json",
		success: function(json) {
			sceneObj.myOptionsArray = json.my_options_array
			sceneObj.cart = json.cart
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function getCorrectEnvironment() {
	let deferred = $.Deferred();
	if (isInvalid(sceneObj.myOptionsArray[0].environment) == false) {
		sceneObj.myEnvironment = sceneObj.myOptionsArray[0].environment
		console.log('sceneObj.myEnvironment', sceneObj.myEnvironment)
	}
	ajaxEnvironmentOnLoad(sceneObj.myEnvironment).then(deferred.resolve);
	return deferred.promise();
}

function ajaxCameraOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/camera_on_load.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene,
		dataType: "json",
		success: function(json) {
			sceneObj.myCameraObj = json.cameraObj
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxGetCenterButtonData() {
	return $.ajax({
		type: "POST",
		url: "php/get_center_button_data.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			// Update global variables if data is present
			if (json.centerModelPosition && json.centerModelTarget) {
				sceneObj.centerModelPosition = json.centerModelPosition;
				sceneObj.centerModelTarget = json.centerModelTarget;
			} else {
				console.warn("No data received for centerModelPosition or centerModelTarget");
			}
		},
		error: function(jqxhr, textStatus, errorThrown) {
			console.error("AJAX error:", textStatus, errorThrown);
			console.error("Response text:", jqxhr.responseText);
		}
	});
}

function ajaxEnvironmentOnLoad(myEnvironment) {
	return $.ajax({
		type: "POST",
		url: "php/environment_scene_on_load.php?dbParam=" + sceneObj.dbParam + "&environment=" + myEnvironment,
		dataType: "json",
		success: function(json) {
			sceneObj.environmentObj = json.my_environment
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function ajaxStaticMaterialsAndTextures() {
	return $.ajax({
		type: "POST",
		url: "php/static_materials_textures.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene,
		dataType: "json",
		success: function(json) {
			sceneObj.staticTextureArray = json.staticTextureArray
			sceneObj.staticMaterialArray = json.staticMaterialArray
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}
