import { sceneObj } from './global_vars.js'


export function playAnimation(name) {
	if (sceneObj.scene.includes('FLIP')) {
		if (name == 'finishing_plate') {
			let speed = 1;
			sceneObj.animationFlip = 'finishing_plate'
			sceneObj.sketchfabAPI.setCycleMode('one', function(err) {
				if (!err) {
				}
			});
			sceneObj.sketchfabAPI.seekTo(0.1, function(err) {
				if (!err) {
				}
			});
			sceneObj.sketchfabAPI.setSpeed(speed, function(err) {
				if (!err) {
				}
			});
			sceneObj.sketchfabAPI.play(speed,function(err) {
				if (!err) {
				}
			});
		}else {
			let speed = -0.5;
			sceneObj.sketchfabAPI.setSpeed(speed, function(err) {
				if (!err) {
				}
			});
			if (sceneObj.animationFlip == 'finishing_plate') {
				sceneObj.sketchfabAPI.seekTo(1.0, function(err) {
					if (!err) {
					}
				});
				sceneObj.sketchfabAPI.play(function(err) {
					if (!err) {
					}
				});
				sceneObj.animationFlip = ''
			}
		}
	}
}