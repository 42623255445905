import { sceneObj } from './global_vars.js'

let centerCount = 0;
let isCenterModelMoving = false;

export function menuButton() {
	if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
		$('.toggle').append('<div id="menu-icon-up-button" class="menu-icon-up">'+
			'<img id="menu-icon-up" srcset="https://cdn.digitaldraping.com/corbin/3d/img/config/chevron_up_@2x.png 2x" '+
			'src="https://cdn.digitaldraping.com/corbin/3d/img/config/chevron_up.png" title="Close Menu" alt="menu" />'+
			'</div>')
		$(document).on('click', '#menu-icon-up-button' , function() {
			$('.buttons').empty();
			$('.buttons-active').removeClass('buttons-active');
			$('.menu-head').removeClass('menu-active')
			sceneObj.menuOpen = 100
			$("#menu-icon-up-button").hide();
		});
	}
	if (sceneObj.preferencesObj.centerButton == 'yes') {
		$('.toggle').append('<div id="menu-center-button" class="center-icon">'+
			'<img id="menu-center" srcset="https://cdn.digitaldraping.com/corbin/3d/img/config/center_@2x.png 2x" '+
			'src="https://cdn.digitaldraping.com/corbin/3d/img/config/center.png" title="Center Model" alt="center" />'+
			'</div>')
	}
	$(document).on('click', '#menu-center-button', function() {
		if (!isCenterModelMoving) {
			centerModelMove();
		}
	});
}


function centerModelMove() {
	if (isCenterModelMoving) return;

	isCenterModelMoving = true;

	sceneObj.sketchfabAPI.setEnableCameraConstraints(false, {preventCameraConstraintsFocus: false}, function(err) {
		if (!err) {
			// To get array values comment out and uncomment out the two functions below.
			moveCameraToNextPosition();
			// centerModel();
		} else {
			isCenterModelMoving = false;
		}
	});
}

function moveCameraToNextPosition() {
	sceneObj.sketchfabAPI.setCameraLookAt(sceneObj.centerModelPosition[centerCount], sceneObj.centerModelTarget[centerCount], 0.5, function(err) {
		if (!err) {
			// window.console.log('Camera moved');
			setTimeout(function(){
				if (centerCount < sceneObj.centerModelPosition.length - 1) {
					centerCount++;
				} else {
					centerCount = 0;
				}
				centerModel();
			}, 500);
		} else {
			isCenterModelMoving = false;
		}
	});
}

function centerModel() {
	sceneObj.sketchfabAPI.recenterCamera(function(err) {
		if (!err) {
			// window.console.log('Camera recentered');
			sceneObj.sketchfabAPI.getCameraLookAt(function(err, camera) {
				// window.console.log(camera.position); // [x, y, z]
				// window.console.log(camera.target); // [x, y, z]
			});
			enableCameraConstraints();
		} else {
			isCenterModelMoving = false;
		}
	});
}

function enableCameraConstraints() {
	sceneObj.sketchfabAPI.setEnableCameraConstraints(true, {preventCameraConstraintsFocus: true}, function(err) {
		if (!err) {
			// window.console.log('Camera constraints enabled');
		}
		isCenterModelMoving = false;
	});
}