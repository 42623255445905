import { sceneObj, params } from './global_vars.js'
import { updateOptionsArray, selectedOptionsArray } from './global.js'
import { stitching_assembly } from './stitching_assembly.js'
import { isInvalid } from './global_popular_functions.js'

// If we have prams lets get them and update the objects, arrays.
export function getURL() {
	let urlConsolLog = []
	return new Promise((resolve) => {
		let database = params.get('db')
		let optionsURL = params.get('options')
		let texturesURL = params.get('textures')
		let logoURL = params.get('logo')
		let stitchPattern = params.get('stitchpattern')
		let studsURL = params.get('studs')
		let heatswitchURL = params.get('heatswitch')
		if (typeof optionsURL !== 'options' && optionsURL) {
			// It's not undefined, null or NaN
			updateOptionsArray(optionsURL.split(','))
			urlConsolLog.push('We have options info in the URL')
		} else {
			urlConsolLog.push('Nada Options URL')
		}
		if (typeof texturesURL !== 'textures' && texturesURL) {
			// It's not undefined, null or NaN
			sceneObj.myTexturesArray = texturesURL.split(',')
			urlConsolLog.push('We have texture info in the URL')
		} else {
			urlConsolLog.push('Nada Texture URL')
		}
		if (typeof stitchPattern !== 'stitchpattern' && stitchPattern) {
			let stitchPatternArray = stitchPattern.split(',')
			sceneObj.stitchPattern = stitchPatternArray[0]
			sceneObj.stitchPatternColor = stitchPatternArray[1]
			urlConsolLog.push('S T: ' + sceneObj.stitchPattern + ' - ' + sceneObj.stitchPatternColor.replace('stitch_pattern_',''))
		}else {
			urlConsolLog.push('Nada Stitch Pattern')
		}
		if (typeof logoURL !== 'logo' && logoURL) {
			// It's not undefined, null or NaN
			let myArray = logoURL.split(',')
			updateLogoArray(myArray[0], [myArray[1],myArray[2],myArray[3],myArray[4]])
			sceneObj.logo = myArray[0]
			urlConsolLog.push('We have logo info in the URL')
		} else {
			urlConsolLog.push('Nada Logo URL')
		}
		if (typeof heatswitchURL !== 'logo' && heatswitchURL) {
			// It's not undefined, null or NaN
			sceneObj.heatswitch = heatswitchURL
			urlConsolLog.push('heatswitchURL: ' +  sceneObj.heatswitch)
		} else {
			urlConsolLog.push('Nada heatswitchURL: ' + sceneObj.heatswitch)
		}
		if (typeof studsURL !== 'studs' && studsURL) {
			// It's not undefined, null or NaN
			sceneObj.stud = studsURL
			urlConsolLog.push('studsURL: ' + sceneObj.stud)
		} else {
			urlConsolLog.push('Nada studsURL')
		}
		sceneObj.consoleLog.url = urlConsolLog
		stitching_assembly()
		resolve("URL")
	})
}

export function setURL() {
	let myURL = "https://" + window.location.hostname + window.location.pathname
	params.set('options', selectedOptionsArray('id'))
	params.set('textures', sceneObj.myTexturesArray)
	if (isInvalid(sceneObj.logo) == false) {
		params.set('logo', sceneObj.logo + ','+getLogoArray())
	}

	if (isInvalid(sceneObj.stud) == false){
		params.set('studs', sceneObj.stud)
	}

	if (!isInvalid(sceneObj.stitchPattern)) {
		params.set('stitchpattern', sceneObj.stitchPattern + ',' + sceneObj.stitchPatternColor)
	}

	let str = params.getAll('textures')[0]
	sceneObj.myTexturesArray = str.split(',')
	history.pushState(null, '', myURL + '?' + params.toString())
}

function getLogoArray() {
	let myArray = []
	myArray.push(sceneObj.logosObj[sceneObj.logo].layer_0)
	myArray.push(sceneObj.logosObj[sceneObj.logo].layer_1)
	myArray.push(sceneObj.logosObj[sceneObj.logo].layer_2)
	myArray.push(sceneObj.logosObj[sceneObj.logo].layer_3)
	return myArray
}

function updateLogoArray(design, colorArray) {
	sceneObj.logosObj[design].layer_0 = colorArray[0]
	sceneObj.logosObj[design].layer_1 = colorArray[1]
	sceneObj.logosObj[design].layer_2 = colorArray[2]
	sceneObj.logosObj[design].layer_3 = colorArray[3]
}