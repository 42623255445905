import { sceneObj } from './global_vars.js'
import { editTexture } from './edit_texture.js'
import { editOrder } from './edit_order.js'
import { editEnviroment } from './edit_enviroment.js'
import { editCamera } from './edit_camera.js'
import { getData } from './edit_get_data.js'

export function editMode() {
	if (sceneObj.edit == 'yes') {
		editYes()
	}
}

function editYes() {
	$('.menu').prepend('<div class="toggle-edit">' +
		'<img srcset="https://cdn.digitaldraping.com/corbin/3d/img/config/chevron_right_black_@2x.png 2x" src="https://cdn.digitaldraping.com/corbin/3d/img/config/chevron_right_black.png" alt="menu" />' +
		'</div>')
	// Hide show the menu
	$(document).on('click', '.toggle-edit', function() {
		for (let i = 0; i < sceneObj.myMaterialsArray.length; i++) {
			$('#' + sceneObj.myMaterialsArray[i]).animate({ height: 'toggle' }, 10)
		}
		$('#logo').animate({ height: 'toggle' }, 10)
		$('.logo-color-menu').animate({ height: 'toggle' }, 10)
		$('#option').animate({ height: 'toggle' }, 10)
		$('#plate').animate({ height: 'toggle' }, 10)
		$('#accessories').animate({ height: 'toggle' }, 10)
		$('#save').animate({ height: 'toggle' }, 10)
		$('#cart').animate({ height: 'toggle' }, 10)
	})
	editCamera()
	getData()
	editTexture()
	editOrder()
	editEnviroment()
}