import { sceneObj } from './global_vars.js'
import { sortArrays } from './global.js'
import { closeEditMenus } from './edit_global.js'

let myNameArray = []
let myOrderArray = []
export function editOrder() {
	$('.configurator').append('<div id="order">' +
	'<div id="order-text" class="settings order">Texture Order</div>' +
	'<div id="order-box" class="box buttons"></div>' +
	'</div>')
}
$(document).on('click', '#order-text', function() {
	closeEditMenus()
	$('#order-box').append('<div class="order-name">Use order_array.php</div>' +
		'<div id="order-update" class="order-update">Update</div>')
	$('.box').removeClass('buttons-active')
	$('#order-box').addClass('buttons-active')
	$.ajax({
		type: "POST",
		url: "php/update/order.php?dbParam=" + sceneObj.dbParam,
		dataType: "json",
		success: function(json) {
			let myNameArray = json.myNameArray
			let myOrderArray = json.myOrderArray
			let mainArray = sortArrays([myOrderArray, myNameArray])
			$('#order-box').append('<div class="order-setting">' +
				'<textarea id="order-textarea" class="css-order" rows="22" cols="33">' + mainArray[1] + '</textarea></div>')
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
})
$(document).on('click', '#order-update', function() {
	myNameArray = $("#order-textarea").val()
	console.log('myNameArray',myNameArray)
	$.ajax({
		type: "POST",
		url: "php/update/order_update.php?dbParam=" + sceneObj.dbParam + "&order=" + myNameArray,
		dataType: "json",
		success: function(json) {
			console.log("order - " + json)
		},
		error: function(jqxhr) {
			// console.log("error - " + jqxhr)
		}
	})
})