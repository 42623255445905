import { sceneObj } from './global_vars.js'
import { addThumbs, createToolTipHTML, updateTick } from './textures_and_textures_loading.js'
import { menuHeaderColor, formatTextTitle, isInvalid } from './global_popular_functions.js'
import { logoBuildMenu } from './logo.js'
import { gotoCameraPosition } from './camera.js'
import { stitchPatternMenu } from './stitch_pattern.js'

// Utility function to find the position of a material in the materials array
export function findMaterialPosition(myCount, activeTexture, activeMaterial) {
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			if (!err) {
				for (let i = 0; i < materials.length; i++) {
					if (materials[i].name === activeMaterial) {
						let materialPosition = i;
						let textureType = sceneObj.everyTexturesAttributesObj[activeTexture].textureType;
						resolve([activeTexture, activeMaterial, textureType, materialPosition]);
						return;
					}
				}
				// If material is not found, resolve with null or an error indicator
				resolve(null);
			} else {
				// Handle error case
				console.error("Error getting material list:", err);
				resolve(null);
			}
		});
	});
}

// Debug logging function
let myNum = 0;
function consoleLog(activeTexture, activeMaterial, letter, myCountNumber) {
	// if (letter === 'A') {
	// 	console.log('myNum Debug count:', myNum);
	// 	console.log('activeTexture:', activeTexture);
	// 	console.log('activeMaterial:', activeMaterial);
	// 	if (myNum === 0) {
	// 		console.log('sceneObj.myMaterialsArray.length:', sceneObj.myMaterialsArray.length);
	// 		console.log('sceneObj.staticMaterialArray.length:', sceneObj.staticMaterialArray.length);
	// 		console.log('sceneObj.staticMaterialArray:', sceneObj.staticMaterialArray);
	// 	}
	// 	myNum++;
	// } else {
	// 	console.log('myCountNumber:', myCountNumber);
	// 	console.log('myStartNumber:', myStartNumber);
	// }
}

// Function to track progress of texture loading
let myStartNumber = 0;


// Initialize the menu after loading textures
function openMenu() {
	sceneObj.activeMaterial = sceneObj.myMaterialsArray[0];
	sceneObj.activeTexture = sceneObj.myTexturesArray[0];
	addThumbs(0);
	menuHeaderColor(sceneObj.activeMaterial);
	updateTick();
	updateMenuOnLoad();
	createToolTipHTML();
}

// Update menu items with loaded textures
function updateMenuOnLoad() {
	for (let i = 0; i < sceneObj.myMaterialsArray.length; i++) {
		let activeTexture = sceneObj.myTexturesArray[i];
		let activeMaterial = sceneObj.myMaterialsArray[i];
		let str = formatTextTitle(activeTexture);
		$(`#${activeMaterial} .menu-head .menu-image`).attr("src", `${sceneObj.texturesFolder}textures/thumb/${activeTexture}.png`);
		$(`#${activeMaterial} .menu-head .menu-text`).html(str);
	}
}

let totalMaterials = 0
// Main function to load default textures
export async function loadDefaultTextures() {
	$('.loader').css('visibility', 'visible');
	myStartNumber = 0;
	totalMaterials = sceneObj.myMaterialsArray.length + sceneObj.staticMaterialArray.length
	console.log('Total materials to process:', totalMaterials);

	// Process custom materials
	for (let i = 0; i < sceneObj.myMaterialsArray.length; i++) {
		let activeTexture = sceneObj.myTexturesArray[i];
		let activeMaterial = sceneObj.myMaterialsArray[i];

		let shouldHide = sceneObj.hideObj.some(item => item[0] === activeMaterial && item[1] === activeTexture);

		if (shouldHide) {
			hideMaterial(activeMaterial);
			myStartNumber++;
		} else {
			await processMaterial(activeTexture, activeMaterial);
		}
	}
	// Process static materials
	for (let x = 0; x < sceneObj.staticMaterialArray.length; x++) {
		let activeTexture = sceneObj.staticTextureArray[x];
		let activeMaterial = sceneObj.staticMaterialArray[x];
		await processMaterial(activeTexture, activeMaterial);
	}
}

// Process a single material
async function processMaterial(activeTexture, activeMaterial) {
	let data = await findMaterialPosition(0, activeTexture, activeMaterial);
	if (data) {
		if (data[2] === 'color') {
			await updateMaterial(data[0], data[1], data[2], data[3], '');
		} else {
			await getTextures(data[0], data[1], data[2], data[3]);
		}
		myStartNumber++;
		//console.log('Processed material:', activeMaterial, 'Current count:', myStartNumber);
		let pName = myStartNumber.toString();

		// Initialize the object chain if it doesn't exist
		sceneObj.consoleLog = sceneObj.consoleLog || {};
		sceneObj.consoleLog.texturesLoading = sceneObj.consoleLog.texturesLoading || {};

		// Now set the property
		sceneObj.consoleLog.texturesLoading[pName] = activeMaterial;
	} else {
		//console.error('Failed to process material:', activeMaterial);
		sceneObj.consoleLog.texturesLoading = 'Failed to process material:', activeMaterial
	}
	if (myStartNumber == totalMaterials) {
		finishLoading();
	}
}

// Finalize the loading process
function finishLoading() {
	console.log('Finished loading all materials');
	console.log(sceneObj)
	if (!isInvalid(sceneObj.logo)) {
		logoBuildMenu();
	}
	if (!isInvalid(sceneObj.stitchPattern)) {
		stitchPatternMenu();
	}
	openMenu();
	gotoCameraPosition(sceneObj.myMaterialsArray[0]);
	$('.loader').css('visibility', 'hidden');
	$('.loading-info').html('');
}

// Hide a specific material
function hideMaterial(materialName) {
	for (const key of Object.keys(sceneObj.myNodes)) {
		let name = sceneObj.myNodes[key].name;
		if (name && name.toLowerCase().includes(materialName)) {
			sceneObj.sketchfabAPI.hide(key, function(err) {
				if (err) {
					console.error('Error hiding material:', materialName, err);
				}
			});
		}
	}
}

// Check if a texture exists and load it if necessary
export function getTextures(activeTexture, activeMaterial, textureType, materialPosition) {
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
			if (!err) {
				let found = textures.some(texture => {
					let getName = texture.name.split("/");
					let getResult = getName[getName.length - 1].slice(0, -4);
					return getResult === activeTexture;
				});

				if (found) {
					let textureUid = textures.find(texture => texture.name.split("/").pop().slice(0, -4) === activeTexture).uid;
					updateMaterial(activeTexture, activeMaterial, textureType, materialPosition, textureUid);
					resolve();
				} else {
					addTexture(activeTexture, activeMaterial, textureType, materialPosition).then(resolve);
				}
			} else {
				console.error('Error getting texture list:', err);
				resolve();
			}
		});
	});
}

// Add a new texture
function addTexture(activeTexture, activeMaterial, textureType, materialPosition) {
	return new Promise((resolve) => {
		let extension = '.jpg';
		sceneObj.sketchfabAPI.addTexture(sceneObj.texturesFolder + 'textures/images/' + activeTexture + extension, function(err, textureUid) {
			if (!err) {
				updateMaterial(activeTexture, activeMaterial, textureType, materialPosition, textureUid);
			} else {
				console.error('Error adding texture:', err);
				$('.loading-per').html(err);
			}
			resolve();
		});
	});
}

// Legacy function for getting textures (consider removing if unused)
function getTexturesWait(activeTexture, activeMaterial, materialPosition) {
	return new Promise((resolve) => {
		var found = 'no';
		sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
			if (!err) {
				for (let x = 0; x < textures.length; x++) {
					let getName = textures[x].name.split("/");
					let getResult = getName[getName.length - 1].slice(0, -4);
					if (getResult === activeTexture) {
						found = 'yes';
						let textureUid = textures[x].uid;
						resolve(textureUid);
						return;
					}
				}
				if (found !== 'yes') {
					let extension = '.jpg';
					sceneObj.sketchfabAPI.addTexture(sceneObj.texturesFolder + 'textures/images/' + activeTexture + extension, function(err, textureUid) {
						if (!err) {
							resolve(textureUid);
						} else {
							console.error('Error adding texture:', err);
							$('.loading-per').html(err);
						}
					});
				}
			} else {
				console.error('Error getting texture list:', err);
				resolve(null);
			}
		});
	});
}

export function updateMaterial(activeTexture, activeMaterial, textureType, materialPosition, textureUid) {
	return new Promise((resolve) => {
		consoleLog(activeTexture,activeMaterial,'A')
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			let textureObj = sceneObj.everyTexturesAttributesObj[activeTexture]
			if (textureType == "color") {
				createColor(materialToUpdate)
				materialToUpdate.channels.EmitColor.enable = false
				materialToUpdate.channels.DiffuseColor.factor = 0
				materialToUpdate.channels.SpecularColor.factor = 0
				materialToUpdate.channels.SpecularF0.factor = textureObj.specular
				materialToUpdate.channels.MetalnessPBR.factor = textureObj.metalness
				materialToUpdate.channels.AlbedoPBR.color[0] = textureObj.color0
				materialToUpdate.channels.AlbedoPBR.color[1] = textureObj.color1
				materialToUpdate.channels.AlbedoPBR.color[2] = textureObj.color2
				materialToUpdate.channels.AlbedoPBR.factor = textureObj.albedo
			}else {
				createTexture(materialToUpdate, textureObj)
				materialToUpdate.channels.AlbedoPBR.enable = true
				materialToUpdate.channels.AlbedoPBR.texture.uid = textureUid
				materialToUpdate.channels.AlbedoPBR.UVTransforms.scale[0] = textureObj.scaleU
				materialToUpdate.channels.AlbedoPBR.UVTransforms.scale[1] = textureObj.scaleV
				materialToUpdate.channels.AlbedoPBR.UVTransforms.offset[0] = textureObj.offsetU
				materialToUpdate.channels.AlbedoPBR.UVTransforms.offset[1] = textureObj.offsetV
				materialToUpdate.channels.SpecularF0.factor = textureObj.specular
				materialToUpdate.channels.MetalnessPBR.factor = textureObj.metalness
				materialToUpdate.channels.AlbedoPBR.UVTransforms.rotation = textureObj.rotation
				materialToUpdate.channels.AlbedoPBR.factor = textureObj.albedo
			}
			// Apply the change
			sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
				updateMaterialBump(textureObj, activeMaterial, materialPosition).then(resolve);
			})
		})
	});
}

function updateMaterialBump(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.bumpName != '') {
				// if (typeof materialToUpdate.channels.BumpMap.texture == null) {
					materialToUpdate.channels.BumpMap = myObject
				// }
				async function getBumpUID() {
					await getTexturesWait(textureObj.bumpName, activeMaterial, materialPosition).then((data) => {
						materialToUpdate.channels.BumpMap.enable = true
						materialToUpdate.channels.BumpMap.factor = textureObj.bump
						materialToUpdate.channels.BumpMap.texture.uid = data
						materialToUpdate.channels.BumpMap.UVTransforms.scale[0] = textureObj.bumpScaleU
						materialToUpdate.channels.BumpMap.UVTransforms.scale[1] = textureObj.bumpScaleV
						materialToUpdate.channels.BumpMap.UVTransforms.offset[0] = textureObj.offsetU
						materialToUpdate.channels.BumpMap.UVTransforms.offset[1] = textureObj.offsetV
						materialToUpdate.channels.BumpMap.UVTransforms.rotation = textureObj.rotation
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialSheen(textureObj, activeMaterial, materialPosition).then(resolve);
						})
					})
				}
				getBumpUID()
			} else {
				materialToUpdate.channels.BumpMap.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialSheen(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}

		})
	});
}

function updateMaterialSheen(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.sheenName != '') {
				materialToUpdate.channels.Sheen = myObject
				async function getSheenUID() {
					await getTexturesWait(textureObj.sheenName, activeMaterial, materialPosition).then((data) => {
						materialToUpdate.channels.Sheen.enable = true
						materialToUpdate.channels.Sheen.texture.uid = data
						materialToUpdate.channels.Sheen.UVTransforms.scale[0] = textureObj.sheenScaleU
						materialToUpdate.channels.Sheen.UVTransforms.scale[1] = textureObj.sheenScaleV
						materialToUpdate.channels.Sheen.UVTransforms.offset[0] = textureObj.offsetU
						materialToUpdate.channels.Sheen.UVTransforms.offset[1] = textureObj.offsetV
						materialToUpdate.channels.Sheen.UVTransforms.rotation = textureObj.rotation
						materialToUpdate.channels.Sheen.factor = textureObj.sheen
						materialToUpdate.channels.Sheen.colorFactor[0] = textureObj.sheenTint0
						materialToUpdate.channels.Sheen.colorFactor[1] = textureObj.sheenTint1
						materialToUpdate.channels.Sheen.colorFactor[2] = textureObj.sheenTint2
						materialToUpdate.channels.SheenRoughness.enable = true
						materialToUpdate.channels.SheenRoughness.factor = textureObj.sheenRoughness
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialRoughness(textureObj, activeMaterial, materialPosition).then(resolve);
						})
					})
				}
				getSheenUID()
			} else if (Number(textureObj.sheen) > 0) {
				materialToUpdate.channels.Sheen.enable = true
				materialToUpdate.channels.Sheen.factor = textureObj.sheen
				materialToUpdate.channels.Sheen.texture = null
				materialToUpdate.channels.Sheen.colorFactor[0] = textureObj.sheenTint0
				materialToUpdate.channels.Sheen.colorFactor[1] = textureObj.sheenTint1
				materialToUpdate.channels.Sheen.colorFactor[2] = textureObj.sheenTint2
				materialToUpdate.channels.SheenRoughness.enable = true
				materialToUpdate.channels.SheenRoughness.factor = textureObj.sheenRoughness
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialRoughness(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			} else {
				materialToUpdate.channels.Sheen.enable = false
				materialToUpdate.channels.SheenRoughness.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialRoughness(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}
		})
	});
}

function updateMaterialRoughness(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.roughnessName != '') {
				materialToUpdate.channels.RoughnessPBR = myObject
				async function getRoughnessUID() {
					await getTexturesWait(textureObj.roughnessName, activeMaterial, materialPosition).then((data) => {
						materialToUpdate.channels.RoughnessPBR.enable = true
						materialToUpdate.channels.RoughnessPBR.factor = textureObj.roughness
						materialToUpdate.channels.RoughnessPBR.texture.uid = data
						materialToUpdate.channels.RoughnessPBR.UVTransforms.scale[0] = textureObj.roughnessScaleU
						materialToUpdate.channels.RoughnessPBR.UVTransforms.scale[1] = textureObj.roughnessScaleV
						materialToUpdate.channels.RoughnessPBR.UVTransforms.offset[0] = textureObj.offsetU
						materialToUpdate.channels.RoughnessPBR.UVTransforms.offset[1] = textureObj.offsetV
						materialToUpdate.channels.RoughnessPBR.UVTransforms.rotation = textureObj.rotation
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialGlossiness(textureObj, activeMaterial, materialPosition).then(resolve);
						})
					})
				}
				getRoughnessUID()
			} else if(textureObj.roughness > 0){
				materialToUpdate.channels.RoughnessPBR.enable = true
				materialToUpdate.channels.RoughnessPBR.factor = textureObj.roughness
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialGlossiness(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}else {
				materialToUpdate.channels.RoughnessPBR.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialGlossiness(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}
		})
	});
}

function updateMaterialGlossiness(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.glossinessName != '') {
				materialToUpdate.channels.GlossinessPBR = myObject
				async function getGlossinessUID() {
					await getTexturesWait(textureObj.glossinessName, activeMaterial, materialPosition).then((data) => {
						materialToUpdate.channels.GlossinessPBR.enable = true
						materialToUpdate.channels.GlossinessPBR.factor = textureObj.glossiness
						materialToUpdate.channels.GlossinessPBR.texture.uid = data
						materialToUpdate.channels.GlossinessPBR.UVTransforms.scale[0] = textureObj.glossinessScaleU
						materialToUpdate.channels.GlossinessPBR.UVTransforms.scale[1] = textureObj.glossinessScaleV
						materialToUpdate.channels.GlossinessPBR.UVTransforms.offset[0] = textureObj.offsetU
						materialToUpdate.channels.GlossinessPBR.UVTransforms.offset[1] = textureObj.offsetV
						materialToUpdate.channels.GlossinessPBR.UVTransforms.rotation = textureObj.rotation
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialOpacity(textureObj, activeMaterial, materialPosition).then(resolve);
						})
					})
				}
				getGlossinessUID()
			} else if(textureObj.glossiness > 0){
				materialToUpdate.channels.GlossinessPBR.enable = true
				materialToUpdate.channels.GlossinessPBR.factor = textureObj.glossiness
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialOpacity(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}else {
				materialToUpdate.channels.GlossinessPBR.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialOpacity(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}
		})
	});
}

function updateMaterialOpacity(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if ((textureObj.opacity !== null) && (textureObj.opacity >= 0) && (textureObj.opacity <= 1)) {
				console.log('textureObj.opacity',textureObj.opacity)
				materialToUpdate.channels.Opacity = myObject
				async function getGlossinessUID() {
						materialToUpdate.channels.Opacity.enable = true
						materialToUpdate.channels.Opacity.factor = textureObj.opacity
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialAlpha(textureObj, activeMaterial, materialPosition).then(resolve);
						})
				}
				getGlossinessUID()
			} else {
				materialToUpdate.channels.Opacity.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialAlpha(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}
		})
	});
}

function isNameInAlpha(myName) {
	return sceneObj.alphaObj.some(subArray => subArray.includes(myName))
}

function updateMaterialAlpha(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		if (isNameInAlpha(activeMaterial)) {
			updateMaterialCavity(textureObj, activeMaterial, materialPosition).then(resolve);
		}else {
			sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
				let materialToUpdate = materials[materialPosition]
				if (textureObj.alpha != '0.00') {
					console.log('textureObj.alpha',textureObj.alpha)
					materialToUpdate.channels.AlphaMask = myObject
					async function getAlphaUID() {
						await getTexturesWait(textureObj.alphaName, activeMaterial, materialPosition).then((data) => {
							materialToUpdate.channels.AlphaMask = {
								enable: true,
								factor: 0.5,
								invert: true,
								texture: {
									internalFormat: "ALPHA",
									magFilter: "LINEAR",
									minFilter: "LINEAR_MIPMAP_LINEAR",
									texCoordUnit: 0,
									textureTarget: "TEXTURE_2D",
									uid: data,
									wrapS: "REPEAT",
									wrapT: "REPEAT",
								},
								UVTransforms: {
									offset: [0, 0],
									rotation: 0,
									scale: [1, 1],
								},
							}
							materialToUpdate.channels.AlphaMask.UVTransforms.scale[0] = 1
							materialToUpdate.channels.AlphaMask.UVTransforms.scale[1] = 1
							materialToUpdate.channels.AlphaMask.UVTransforms.offset[0] = 0
							materialToUpdate.channels.AlphaMask.UVTransforms.offset[1] = 0
							materialToUpdate.channels.AlphaMask.UVTransforms.rotation = 0
							materialToUpdate.channels.AlphaMask.enable = true
							materialToUpdate.channels.AlphaMask.factor = "0.35"
							materialToUpdate.channels.AlphaMask.invert = false
							materialToUpdate.channels.AlphaMask.texture.internalFormat = 'alpha'
							materialToUpdate.channels.AlphaMask.texture.uid = data
							sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
								updateMaterialCavity(textureObj, activeMaterial, materialPosition).then(resolve);
							})
						})
					}
					getAlphaUID()
				} else {
					materialToUpdate.channels.AlphaMask.enable = false
					sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
						updateMaterialCavity(textureObj, activeMaterial, materialPosition).then(resolve);
					})
				}
			})
		}
	});
}

function updateMaterialCavity(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.cavityName != '') {
				materialToUpdate.channels.CavityPBR = myObject
				async function getCavityUID() {
					await getTexturesWait(textureObj.cavityName, activeMaterial, materialPosition).then((data) => {
						materialToUpdate.channels.CavityPBR.enable = true
						materialToUpdate.channels.CavityPBR.factor = textureObj.cavity
						materialToUpdate.channels.CavityPBR.texture.uid = data
						materialToUpdate.channels.CavityPBR.UVTransforms.scale[0] = textureObj.cavityScaleU
						materialToUpdate.channels.CavityPBR.UVTransforms.scale[1] = textureObj.cavityScaleV
						materialToUpdate.channels.CavityPBR.UVTransforms.offset[0] = textureObj.offsetU
						materialToUpdate.channels.CavityPBR.UVTransforms.offset[1] = textureObj.offsetV
						materialToUpdate.channels.CavityPBR.UVTransforms.rotation = textureObj.rotation
						sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
							updateMaterialMix(textureObj, activeMaterial, materialPosition).then(resolve);
						})
					})
				}
				getCavityUID()
			} else {
				materialToUpdate.channels.CavityPBR.enable = false
				sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
					updateMaterialMix(textureObj, activeMaterial, materialPosition).then(resolve);
				})
			}
		})
	});
}

function updateMaterialMix(textureObj, activeMaterial, materialPosition){
	return new Promise((resolve) => {
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			let materialToUpdate = materials[materialPosition]
			if (textureObj.glossiness > 0) {
				materialToUpdate.channels.GlossinessPBR.enable = true
				materialToUpdate.channels.GlossinessPBR.factor = textureObj.glossiness
			} else {
				materialToUpdate.channels.GlossinessPBR.enable = false
				materialToUpdate.channels.GlossinessPBR.factor = textureObj.glossiness
			}
			if (textureObj.roughness > 0) {
				materialToUpdate.channels.RoughnessPBR.enable = true
				materialToUpdate.channels.RoughnessPBR.factor = textureObj.roughness
			} else {
				materialToUpdate.channels.RoughnessPBR.enable = false
			}
			if ((textureObj.alpha > 0) && (!isNameInAlpha(activeMaterial))) {
				materialToUpdate.channels.AlphaMask.enable = true
				materialToUpdate.channels.AlphaMask.factor = textureObj.alpha
			} else if (!isNameInAlpha(activeMaterial)){
				materialToUpdate.channels.AlphaMask.enable = false
			}
			if (textureObj.clearCoat > 0) {
				materialToUpdate.channels.ClearCoat.enable = true
				materialToUpdate.channels.ClearCoat.factor = textureObj.clearCoat
			} else {
				materialToUpdate.channels.ClearCoat.enable = false
			}
			sceneObj.sketchfabAPI.setMaterial(materialToUpdate, function() {
				// myStartNumber++
				$('.loader').css('visibility','hidden')
				resolve()
			})
		})
	});
}

const myObject = {
	enable: true,
	factor: 1,
	texture: {
		internalFormat: 'RGB',
		magFilter: 'LINEAR',
		minFilter: 'LINEAR_MIPMAP_LINEAR',
		texCoordUnit: 0,
		textureTarget: 'TEXTURE_2D',
		uid: '',
		wrapS: 'REPEAT',
		wrapT: 'REPEAT'
	},
	UVTransforms: {
		offset: [0, 0],
		rotation: 0,
		scale: [0, 0],
		enable: true,
		factor: 1
	},
	colorFactor: [0, 0, 0]
}
const myObjectColor = {
	enable: true,
	factor: 1,
	color: [0, 0, 0]
}
function createTexture(materialToUpdate, textureObj) {
	materialToUpdate.channels.AlbedoPBR = myObject
	materialToUpdate.channels.BumpMap = myObject
	materialToUpdate.channels.Sheen = myObject
	materialToUpdate.channels.CavityPBR = myObject
	materialToUpdate.channels.GlossinessPBR = myObject

	if ((textureObj.alpha > 0)&&(!isNameInAlpha(activeMaterial))) {
		materialToUpdate.channels.AlphaMask = myObject
	}
	materialToUpdate.channels.RoughnessPBR = myObject
}
function createColor(materialToUpdate) {
	materialToUpdate.channels.AlbedoPBR = myObjectColor
	materialToUpdate.channels.BumpMap = myObject
	materialToUpdate.channels.Sheen = myObject
	materialToUpdate.channels.CavityPBR = myObject
	materialToUpdate.channels.GlossinessPBR = myObject
	materialToUpdate.channels.RoughnessPBR = myObject
}