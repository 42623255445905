// If there is a stitch_patten (you can find this in the Database "scenes")
// Then this code will run

// sceneObj.stitchPatternAlphaArray = ['no_pattern', 'diamond', 'eagle', 'flame', 'guns', 'tribal', 'honeycomb']
// sceneObj.stitchPatternColorArray = ['stitch_pattern_black', 'stitch_pattern_burgundy'...]

import { sceneObj, params } from './global_vars.js'
import { formatTextTitle, menuHeaderColor, isInvalid } from './global_popular_functions.js'
import { gotoCameraPosition } from './camera.js'
import { setURL } from './url.js'

function getColorsArray() {
	Object.entries(sceneObj.everyTexturesAttributesObj).forEach(item => {
		if (item[0].includes('stitch_pattern')) {
			sceneObj.stitchPatternColorArray.push(item[0])
		}
	})
	// console.log('sceneObj.stitchPatternColorArray',sceneObj.stitchPatternColorArray)
	buildMenu()
}

function buildMenu() {
	let lastElement = sceneObj.myMaterialsArray[sceneObj.myMaterialsArray.length - 1]
	$('#' + lastElement).after('<div id="stitch-pattern">' +
		'<div id="stitch-pattern-menu-head" class="menu-head" name="stitch-pattern">' +
		'stitch pattern' +
		'<img class="menu-image" src="' + sceneObj.texturesFolder + 'stitch_patterns/thumb/' + sceneObj.stitchPattern + '.png">' +
		'<div class="menu-text">' + sceneObj.stitchPattern + ' | ' + sceneObj.stitchPatternColor.replace('stitch_pattern_', '') + '</div>' +
		'</div>' +
		'<div id="buttons-stitch-pattern" class="buttons"></div>' +
		'<div id="buttons-stitch-pattern-container"></div>' +
		'</div>')
	stitchPattern()
}

export function stitchPatternMenu() {
	// Lets get the stitch pattern data
	$.ajax({
		type: "POST",
		url: "php/stitch_pattern.php?dbParam=" + sceneObj.dbParam + "&scene_name=" + sceneObj.scene,
		dataType: "json",
		success: function(json) {
			sceneObj.stitchPatternArray = json
			// Have we got from URL
			if (sceneObj.stitchPatternColor == '') {
				sceneObj.stitchPatternColor = sceneObj.stitchPatternArray.default_color
			}
			// console.log('sceneObj.stitchPatternArray',sceneObj.stitchPatternArray)
			sceneObj.stitchPatternAlphaArray = []
			Object.entries(json).forEach(item => {
				if (item[0].includes('_pattern')) {
					if (item[1] == 'yes') {
						sceneObj.stitchPatternAlphaArray.push(item[0].replace('_pattern', ''))
					}
				}
			})
			// console.log('sceneObj.stitchPatternAlphaArray',sceneObj.stitchPatternAlphaArray)
			getColorsArray()
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}

function gotValuesURL() {
	if (sceneObj.stitchPattern !== '') {

	}
}


function createTextureArray() {
	let myArray = [] // extention, theName, address, type, material
	let sT = sceneObj.stitchPattern
	let tF = sceneObj.texturesFolder
	let sN = sceneObj.scene.toLowerCase()
	let pC = sceneObj.stitchPatternColor
	let pS = sceneObj.stitchPatternArray.passenger_seat
	let oneArray = ['png', sT, tF + 'stitch_patterns/images/' + sN + '/' + sT + '.png', 'alpha', 'stitching_pattern']
	let twoArray = ['jpg', pC, tF + 'textures/images/' + pC + '.jpg', 'color', 'stitching_pattern']
	if (isInvalid(pS) == false) {
		myArray = [
			oneArray,
			twoArray,
			['png', sT + '_' + pS, tF + 'stitch_patterns/images/' + sN + '/' + sT + '_' + pS + '.png', 'alpha', '50s_stitching_pattern'],
			['jpg', pC, tF + 'textures/images/' + pC + '.jpg', 'color', '50s_stitching_pattern']
		]
	} else {
		myArray = [
			oneArray,
			twoArray
		]
	}
	return myArray
}

export async function stitchPattern() {
	if (!isInvalid(sceneObj.stitchPattern)) {
		const myArray = createTextureArray();
		const haveWeGotTheTexture = (extention, theName, address, type, material) => {
			return new Promise((resolve, reject) => {
				sceneObj.sketchfabAPI.getTextureList((err, textures) => {
					if (err) {
						console.error('Error getting texture list:', err);
						return reject(err);
					}
					const texture = textures.find(({ name }) => name.split("/").pop().slice(0, -4) === theName);
					if (texture) {
						loadStitchPatternTextures(texture.uid, type, material, theName);
						return resolve('resolved');
					}
					sceneObj.sketchfabAPI.addTexture(address, (err, textureUid) => {
						if (err) {
							console.error('Error adding texture:', address, err);
							return reject(err);
						}
						loadStitchPatternTextures(textureUid, type, material, theName);
						resolve('resolved');
					});
				});
			});
		};
		const loadAllStitchPatterns = async () => {
			for (const textureParams of myArray) {
				try {
					await haveWeGotTheTexture(...textureParams);
				} catch (error) {
					console.error('Error loading stitch pattern:', error);
				}
			}
		};
		await loadAllStitchPatterns();
	}
}



// Click Stitch Pattern Designs Menu
$(document).on('click', '#stitch-pattern-menu-head', function() {
	stitchMenuClick($(this).parent().attr('id'))
})

export function stitchMenuClick(name) {
	// need to add this as we have added advent click (green)
	sceneObj.activeMaterial = name
	loadStitchPatternThumbs()
	gotoCameraPosition('seat')
	highlightUpdateMaterial('', 'stitching_pattern')
}

async function highlightUpdateMaterial(index, name) {
	try {
		// Convert the callback-based function to a Promise
		const materials = await new Promise((resolve, reject) => {
			sceneObj.sketchfabAPI.getMaterialList((err, materials) => {
				if (err) {
					reject(err);
				} else {
					resolve(materials);
				}
			});
		});

		// Find the material position
		let materialPosition = materials.findIndex(material => material.name === name);

		if (materialPosition === -1) {
			throw new Error(`Material "${name}" not found`);
		}

		// Highlight the material
		await sceneObj.sketchfabAPI.highlightMaterial(materials[materialPosition]);
	} catch (error) {
		console.error("Error updating material:", error);
	}
}

export function loadStitchPatternThumbs() {
	menuHeaderColor()
	if (sceneObj.menuOpen == 'stitch-pattern') {
		$('.buttons').empty();
		$('.buttons-active').removeClass('buttons-active');
		$('.menu-head').removeClass('menu-active')
		sceneObj.menuOpen = 100
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").hide();
		}
	}else {
		$('.buttons').empty()

		for (let i = 0; i < sceneObj.stitchPatternAlphaArray.length; i++) {
			$('#buttons-stitch-pattern').append('<div  class="thumb-stitch-pattern active toolTexture" id="' + sceneObj.stitchPatternAlphaArray[i] + '" style="background-image: url(' + sceneObj.texturesFolder + 'stitch_patterns/thumb/' + sceneObj.stitchPatternAlphaArray[i] + '.png")"></div>')
		}

		$('#buttons-stitch-pattern').append('<div id="stitch-pattern-spacer"></div>')

		$('#buttons-stitch-pattern').append('<div class="stitch-pattern-color-menu" id="stitch-pattern-color">' +
			'<div class="menu-head-stitch-pattern-thumbs menu-active" name="stitch-pattern-color">Color<span class="stitch-pattern-text"></span>' +
			'<img class="menu-image" src="' + sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.stitchPatternColor + '.png">' +
			'<div class="menu-text">' + sceneObj.stitchPatternColor.replace('stitch_pattern_', '') + '</div>')

		for (let i = 0; i < sceneObj.stitchPatternColorArray.length; i++) {
			$('#stitch-pattern-color').append('<div  class="thumb-stitch-pattern-color active toolTexture" id="' + sceneObj.stitchPatternColorArray[i] + '" style="background-image: url(' + sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.stitchPatternColorArray[i] + '.png")"></div>')
		}
		updateStitchColorTick()
		updateStitchAlphaTick()
		gotoCameraPosition('seat')
		sceneObj.menuOpen == 'stitchPattern'
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").show();
		}
	}
}

// Click on an Alpha thumb
$(document).on('click', '.thumb-stitch-pattern', function() {
	let name = $(this).attr('id')
	sceneObj.stitchPattern = name
	stitchPattern()
	updateStitchAlphaTick()
	updateStitchMenusAlpha()
	setURL()
})

// Click on an Color thumb
$(document).on('click', '.thumb-stitch-pattern-color', function() {
	let name = $(this).attr('id')
	sceneObj.stitchPatternColor = name
	stitchPattern()
	updateStitchColorTick()
	updateStitchMenusColor()
	setURL()
})

// Update the tick
function updateStitchColorTick() {
	$('#buttons-stitch-pattern').children('div').children('.thumb-stitch-pattern-color').empty()
	$('#buttons-stitch-pattern').children('div').children('#' + sceneObj.stitchPatternColor).append('<img src="' + sceneObj.texturesFolder + 'config/tick.png ">')
}

function updateStitchAlphaTick() {
	$('#buttons-stitch-pattern').children('.thumb-stitch-pattern').empty()
	$('#buttons-stitch-pattern').children('#' + sceneObj.stitchPattern).append('<img src="' + sceneObj.texturesFolder + 'config/tick.png ">')
}
// Alpha Update menu text and small image
function updateStitchMenusAlpha() {
	$('#stitch-pattern').children('div').children('.menu-text').html(formatTextTitle(sceneObj.stitchPattern))
	$('#stitch-pattern').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'stitch_patterns/thumb/' + sceneObj.stitchPattern + '.png')
}
// Color Update menu text and small image
function updateStitchMenusColor() {
	$('#stitch-pattern-color').children('div').children('.menu-text').html(formatTextTitle(sceneObj.stitchPatternColor))
	$('#stitch-pattern-color').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.stitchPatternColor + '.png')
}

// function loadMaterialList() {
// 	setTimeout(function(){
// 		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
// 			if (!err) {
// 				window.console.log(materials);
// 			}
// 		});
// 	},3000)
// }

function loadStitchPatternTextures(textureUid, type, material, theName) {
	// console.log('theName',theName)
	// console.log('material',material)
	// console.log('type', type)
	//if (material.includes('50s_stitching_pattern')){
	//}else {
	sceneObj.sketchfabAPI.getMaterialList((err, materials) => {
		if (err) {
			console.error('Error getting material list:', err);
			return;
		}

		const materialObj = materials.find(mat => mat.name === material);
		if (!materialObj) {
			console.error('Material not found:', material);
			return;
		}

		if (type === 'alpha') {
			setAlphaMask(materialObj, textureUid);
		} else {
			setAlbedoPBR(materialObj, textureUid, theName);
		}
	});
	//}
}

function setAlphaMask(material, textureUid) {
	const alphaMask = {
		enable: true,
		factor: 0.3,
		invert: false,
		texture: {
			internalFormat: "ALPHA",
			magFilter: "LINEAR",
			minFilter: "LINEAR_MIPMAP_LINEAR",
			texCoordUnit: 0,
			textureTarget: "TEXTURE_2D",
			uid: textureUid,
			wrapS: "REPEAT",
			wrapT: "REPEAT",
		},
		UVTransforms: {
			offset: [0, 0],
			rotation: 0,
			scale: [1, 1],
		},
	};
	material.channels.AlphaMask = alphaMask;
	sceneObj.sketchfabAPI.setMaterial(material, () => {
		// console.log('AlphaMask set for material', material.name);
	});
}

function setAlbedoPBR(material, textureUid, theName) {
	const textureObj = sceneObj.everyTexturesAttributesObj[theName];
	const albedoPBR = {
		enable: true,
		factor: 1,
		texture: {
			internalFormat: 'RGB',
			magFilter: 'LINEAR',
			minFilter: 'LINEAR_MIPMAP_LINEAR',
			texCoordUnit: 0,
			textureTarget: 'TEXTURE_2D',
			uid: textureUid,
			wrapS: 'REPEAT',
			wrapT: 'REPEAT',
		},
		UVTransforms: {
			offset: [textureObj.offsetU, textureObj.offsetV],
			rotation: textureObj.rotation,
			scale: [textureObj.scaleU, textureObj.scaleV],
			enable: true,
			factor: 1,
		},
		colorFactor: [0, 0, 0],
	};

	material.channels.AlbedoPBR = albedoPBR;
	updateMaterialChannels(material, textureObj);

	sceneObj.sketchfabAPI.setMaterial(material, () => {
		// console.log('AlbedoPBR set for material', material.name);
	});
}

function updateMaterialChannels(material, textureObj) {
	material.channels.SpecularF0.factor = textureObj.specular;
	material.channels.MetalnessPBR.factor = textureObj.metalness;
	material.channels.AlbedoPBR.factor = textureObj.albedo;

	const cavityPBR = {
		enable: true,
		factor: textureObj.cavity,
		UVTransforms: {
			scale: [textureObj.cavityScaleU, textureObj.cavityScaleV],
			offset: [textureObj.offsetU, textureObj.offsetV],
			rotation: textureObj.rotation,
		},
	};

	material.channels.CavityPBR = cavityPBR;
	sceneObj.sketchfabAPI.setMaterial(material, () => {
		// console.log('CavityPBR set for material', material.name);
		updateRoughness(material, textureObj);
	});
}

function updateRoughness(material, textureObj) {
	material.channels.RoughnessPBR.enable = true;
	material.channels.RoughnessPBR.factor = textureObj.roughness;

	sceneObj.sketchfabAPI.setMaterial(material, () => {
		// console.log('RoughnessPBR set for material', material.name);
	});
}