import { sceneObj } from './global_vars.js'
import { firstImpression } from './global.js'

export function help() {

	// const params = new Proxy(new URLSearchParams(window.location.search), {
	//   get: (searchParams, prop) => searchParams.get(prop),
	// });
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get('myParam');

	// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
	let helpValue = urlParams.get('help'); // "some_value"
	sceneObj.consoleLog.help = 'help: yes/no: ' + helpValue + ' | firstImpression true/false: ' + firstImpression()

	// Delete cookie
	// Use for testing
	// firstImpression(null)

	if (helpValue == 'no') {
		$('#modal').hide();
	}else {
		if ((firstImpression() == true) || (helpValue == 'yes')) {
			$('#modal').load('include/modal-help.html');
			$('#modal').show();
			console.log('first time visiting the site')
		}
	}


	$(document).on('click', '.help-close' , function() {
		$('#modal').hide();
	});

	$(document).on('click', '.help-open' , function() {
		$('#modal').load('include/modal-help.html');
		$('#modal').show();
	});
}