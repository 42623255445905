
export function shortURL(myLongURL) {
	return new Promise((resolve) => {
		$.ajax({
			method: "POST",
			url: "php/url.php",
			dataType: 'json',
			data: { "myLongURL": myLongURL }
		})
		.done(function(data) {
			resolve(data.shorturl)
			// console.log("success: ", data.shorturl);
		})
		.fail(function(jqxhr) {
			// var accessToken = "b96be831fd86c78531a397faf49bea9352012fe0"
			// var params = {
			// 	"long_url" : myLongURL
			// }
			// $.ajax({
			// 	url: "https://api-ssl.bitly.com/v4/shorten",
			// 	cache: false,
			// 	dataType: "json",
			// 	method: "POST",
			// 	contentType: "application/json",
			// 	beforeSend: function (xhr) {
			// 		xhr.setRequestHeader("Authorization", "Bearer " + accessToken)
			// 	},
			// 	data: JSON.stringify(params)
			// }).done(function(data) {
			// 	console.log("success bitly V4- ", data.link)
			// 	resolve(data.link)
			// }).fail(function(data) {
			// 	console.log('fail - ' + data.link)
			// 	resolve(myLongURL)
			// })
			console.log("error", jqxhr)
		})
		.always(function() {
		})
	})
}




// export function shortURL(myLongURL) {
// 	return new Promise((resolve) => {
// 		let phpLongURL = encodeURIComponent(myLongURL)
// 		console.log('long','https://mcseat.com/demo/php/short.php?longURL=' + myLongURL)
// 		let api_url = 'https://mcseat.com/demo/php/short.php?longURL=' + phpLongURL
// 		$.ajax({
// 			type: "GET",
// 			url: api_url,
// 			success: function(data) {
// 				if( data ) {
// 					console.log('success mcseat', data)
// 					resolve(data)
// 				}else {
// 					console.log('success mcseat but no data?', data)
// 					var accessToken = "b96be831fd86c78531a397faf49bea9352012fe0"
// 					var params = {
// 						"long_url" : myLongURL
// 					}
// 					$.ajax({
// 						url: "https://api-ssl.bitly.com/v4/shorten",
// 						cache: false,
// 						dataType: "json",
// 						method: "POST",
// 						contentType: "application/json",
// 						beforeSend: function (xhr) {
// 							xhr.setRequestHeader("Authorization", "Bearer " + accessToken)
// 						},
// 						data: JSON.stringify(params)
// 					}).done(function(data) {
// 						console.log("success bitly V4- ", data.link)
// 						resolve(data.link)
// 					}).fail(function(data) {
// 						console.log('fail - ' + data.link)
// 						resolve(myLongURL)
// 					})
// 				}
// 			},
// 			error: function(err) {
// 				console.log('error - bitly')
// 				var accessToken = "b96be831fd86c78531a397faf49bea9352012fe0"
// 				var params = {
// 					"long_url" : myLongURL
// 				}
// 				$.ajax({
// 					url: "https://api-ssl.bitly.com/v4/shorten",
// 					cache: false,
// 					dataType: "json",
// 					method: "POST",
// 					contentType: "application/json",
// 					beforeSend: function (xhr) {
// 						xhr.setRequestHeader("Authorization", "Bearer " + accessToken)
// 					},
// 					data: JSON.stringify(params)
// 				}).done(function(data) {
// 					console.log("success bitly V4- ", data.link)
// 					resolve(data.link)
// 				}).fail(function(data) {
// 					console.log('fail - ' + data.link)
// 					resolve(myLongURL)
// 				})
// 			}
// 		})
// 	})
// }