import { sceneObj } from './global_vars.js'

export function editCamera() {
	$('.configurator').append('<div id="camera" class="settings camera">camera' +
	'<div class="camera-text">Camera settings will be saved for the menu item that is currently selected.</div>' +
	'</div>')
}

$(document).on('click', '#camera', function() {
	if (sceneObj.activeMaterial != 'option') {
		sceneObj.sketchfabAPI.getCameraLookAt(function(err, camera) {
			let array = $.merge(camera.position, camera.target)
			$.ajax({
				type: "POST",
				url: "php/update/camera.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene + '&materialName=' + sceneObj.activeMaterial + '&myArray=' + array,
				dataType: "json",
				success: function(json) {
					console.log("json - " + json)
					ajaxCameraOnLoad()
				},
				error: function(jqxhr) {
					console.log("error - " + jqxhr)
				}
			})
		})
	} else {
		sceneObj.sketchfabAPI.getCameraLookAt(function(err, camera) {
			let array = $.merge(camera.position, camera.target)
			$.ajax({
				type: "POST",
				url: "php/update/options_camera.php?dbParam=" + sceneObj.dbParam + "&ID=" + sceneObj.activeOption + '&myArray=' + array,
				dataType: "json",
				success: function(json) {
					console.log(json)
					// Refresh the data
						 $.ajax({
							type: "POST",
							url: "php/options_on_load.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene,
							dataType: "json",
							success: function(json) {
								sceneObj.myOptionsArray = json.my_options_array
							},
							error: function(jqxhr) {
								console.log("error - " + jqxhr)
							}
						})
				},
				error: function(jqxhr) {
					console.log("error - " + jqxhr)
				}
			})
		})
	}
})

function ajaxCameraOnLoad() {
	return $.ajax({
		type: "POST",
		url: "php/camera_on_load.php?dbParam=" + sceneObj.dbParam + "&sceneName=" + sceneObj.scene,
		dataType: "json",
		success: function(json) {
			sceneObj.myCameraObj = json.cameraObj
		},
		error: function(jqxhr) {
			console.log("error - " + jqxhr)
		}
	})
}