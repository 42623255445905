import { sceneObj } from './global_vars.js'
import { startTextures } from './textures.js'
import { correctSettings } from './enviroment.js'
import { getURL } from './url.js'
import { visibleMaterials } from './global.js'
import { highlightUpdateMaterial } from './highlight.js'
import { isInvalid } from './global_popular_functions.js'
import { menuButton } from './menu_button.js'

export function initialize() {

	// Initialize the viewer
	let iframe = document.getElementById('api-frame')
	// scene udi. Find using Embed code. } from './textures.js'
	let uid = sceneObj.uid
	// console.log('sceneObj.uid', sceneObj.uid)
	// By default, the latest version of the viewer API will be used.
	let client = new Sketchfab(iframe)
	// Alternatively, you can request a specific version.
	// let client = new Sketchfab( '1.12.0', iframe )
	client.init(uid, {
		ui_settings: 1,
		ui_controls: 0,
		ui_watermark: 0,
		ui_hint: 0,
		ui_infos: 0,
		ui_inspector: 0,
		ui_loading: 0,
		ui_start: 0,
		transparent: 1, // needed for download image.
		animation_autoplay: 0,
		ui_stop: 0,
		annotations_visible: 0,
		ui_annotations: 0,
		//camera: 0,
		//preload: 1,
		success: function onSuccess(api) {
			api.start()

			// Show % load of mesh
			api.addEventListener('modelLoadProgress', function(eventData) {
				let meshPercent = Math.floor(eventData.progress * 100)
				$('.percent').html(meshPercent)
			})

			// Sometimes you accidentally select the whole screen, this will unselect
			api.addEventListener(
				'nodeMouseEnter',
				function(node) {
					if (window.getSelection) {
						window.getSelection().removeAllRanges();
					}
				},
				{ pick: 'slow' }
			);

			api.addEventListener('viewerready', function() {
				// API is ready to use
				console.log('Viewer is ready!! API Ready!!')
				$('.percent').html('')
				$('.percent-info').html('')
				setTimeout(function(){
					$('#movie').css('visibility','hidden')
				},2000)
				sceneObj.sketchfabAPI = api
				// We need to get the nodes to know which to turn off
				// Lets only get this data once. This gets all nodes for this scene
				function getNodes() {
					return new Promise((resolve) => {
						if (sceneObj.myNodes.length < 1) {
							api.getNodeMap(function(err, nodes) {
								if (!err) {
									sceneObj.myNodes = nodes
									// console.log('sceneObj.myNodes',sceneObj.myNodes)
									visibleMaterials()
									resolve("A")
								}else {
									console.log(err)
								}
							})
						} else {
							resolve("A")
						}
					})
				}
				function getNodesAndURL() {
					$.when( getURL(), getNodes() ).done(function() {
						console.log('Start Build')
						menuButton()
						myWindowSize()
						startTextures()
						correctSettings()
						loadMaterialList()
					})
				}

				// Click on model and change menu, open menu.
				api.setHighlightOptions({
				  outlineWidth: 5,
				  outlineColor: [0, 0.6, 0.3],
				  outlineDuration: 2,
				  highlightColor: [0.0, 1.0, 1.0],
				  highlightDuration: 1
				})
				api.addEventListener(
					'click',
					function(info) {
						if (info && info.material){
							let name = info.material.name
							let index = $('#'+name).children().attr('name')
							if ((!sceneObj.staticMaterialArray.includes(name))&&(name != 'inside')){
								highlightUpdateMaterial(index,name)
							}
						}else {
						}
					}
				);
				// END.
				getNodesAndURL()
			})
		}
	})
}

// We zoom out when we are on smaller screens
// We also adjust the sceneObj.environmentObj.zoomOut
// 1.4 -> 3

// Lets make the zoom out on small
function myWindowSize() {
	// Are you on a touch screen?
	sceneObj.touchScreen = matchMedia('(hover: none)').matches;
	let myWindowWidth = $(window).width()
	sceneObj.windowWidth = myWindowWidth

	sceneObj.myResize = 1500 / myWindowWidth
	if (sceneObj.myResize < 1) {
		sceneObj.myResize = 1
	}
	if (myWindowWidth < 500) {
		sceneObj.environmentObj.zoomOut = "3"
	}
}
$(window).resize(function() {
	myWindowSize()
})



function loadMaterialList() {
	setTimeout(function(){
		sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
			if (!err) {
				window.console.log(materials);
			}
		});
	},4000)
}

