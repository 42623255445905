import { sceneObj } from './global_vars.js'
import { shortURL } from './url_short.js'
import { menuHeaderColor } from './global_popular_functions.js'
import { correctBackground } from './enviroment.js'
import { drawIllustrations } from './draw_rectangle.js'

export function addDownloadMenu() {
	$('.configurator').append(`
		<div id="save">
			<div id="save-menu-head" class="menu-head" name="save">
				Download, Link ${sceneObj.preferencesObj.email === 'yes' ? '&#38; Email' : ''}
			</div>
			<div id="buttons-save" class="buttons">
			</div>
		</div>
	`);
}

$(document).on('click', '#save-menu-head', function() {
	sceneObj.activeMaterial = $(this).parent().attr('id')
	menuHeaderColor()
	$('.buttons').empty()
	addSaveHead()
	drawIllustrations()
})

function addSaveHead() {
	$('#buttons-save').append(`
		<div class="option-box-save">
			<p id="download" class="button-one">
				Download Image
			</p>
			<p class="download-info">
				The light blue box approximately shows the boundary of the downloaded image
			</p>
		</div>
		<div class="option-box-save">
			<p id="copy-link" class="link"></p>
			<p id="hide" class="copy-link button-one saveTippy" data-clipboard-target="#copy-link">
				Getting Link
			</p>
		</div>
		${sceneObj.preferencesObj.email === 'yes'
			? `<div class="option-box-save">
				<div id="email-info">
					<p id="sendMyEmail" class="button-one">
						Contact &#47; Send Email
					</p>
				</div>
			</div>`
			: ''
		}
	`);
	getShortURL();
}

/////////////////////////////////////////////////////////////// LINK
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

async function getShortURL() {
	await shortURL(window.location.href).then((data) => {
		addLinkToMenu(data)
	})
}

function addLinkToMenu(url) {
	$('#hide').html('Copy Link')
	$('#copy-link').empty()
	$('#copy-link').append(url)
	tippy('.saveTippy', {
		content: 'Copied',
		allowHTML: true,
		theme: 'light',
		trigger: 'click',
		placement: 'left',
		onShow(instance) {
			setTimeout(() => {
				instance.hide()
			}, 1000)
		}
	})
}

/////////////////////////////////////////////////////////////// DOWNLOAD
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

// Click Download
$(document).on('click', '#download', function() {
	(async () => {
		const inputValue = "1500"
		const inputOption = {
			'transparent': 'Transparent',
			'white': 'White',
			'black': 'Black'
		  }

		const { value: formValues } = await Swal.fire({
			title: 'Download Your Image',
			html: '<div class="swal-text">100<span class="small-text">min</span> - 3000<span class="small-text">max (px)</span></div>' +
			'<input id="imageSize" name="imageSize" class="swal2-input swal22-input" value="1500" maxlength="4"><br /><br />' +
			'<div class="swal-text">Select your background</div>',
			input: 'radio',
			inputValue: inputValue,
			inputOptions: inputOption,
			showCancelButton: true,
			inputValidator: (value) => {
				if ((!value)) {
					return 'Please select a background.'
				}else {
					let imageSize = document.getElementById('imageSize').value
					// console.log('inputValue - ' + imageSize)
					// console.log('radioValue - ' + value)
					if (value == 'transparent') {
						sceneObj.sketchfabAPI.setBackground({transparent: true}, function() {
						})
					}
					if (value == 'white') {
						sceneObj.sketchfabAPI.setBackground({transparent: false, color: [0.95, 0.95, 0.95]}, function() {
						})
					}
					if (value == 'black') {
						sceneObj.sketchfabAPI.setBackground({transparent: false, color: [0.1, 0.1, 0.1]}, function() {
						})
					}
					if (imageSize == null) {} else {
						if (Number(imageSize) > 3000) {
							imageSize = 3000
						}
						if (Number(imageSize) < 100) {
							imageSize = 100
						}
						if (Number(imageSize) == NaN) {
							imageSize = 100
						}
						sceneObj.sketchfabAPI.getScreenShot(imageSize, imageSize, 'image/jpg', function(err, result) {
							let tempName = ""
							let tempArray = []
							// Get array of visible ids in .configurator div
							var ids = $.map($('.configurator div'), function(n, i) {
								if (n.id != "") {
									if ($('#' + n.id).is(':visible')) {
										tempArray.push(n.id)
									}
								}
							})
							// Create the name
							for (let i = 0; i < sceneObj.myTexturesArray.length; i++) {
								if (jQuery.inArray(sceneObj.myMaterialsArray[i], tempArray) != -1) {
									// is in array
									tempName += "(" + sceneObj.myMaterialsArray[i] + "-" + sceneObj.myTexturesArray[i] + ")"
								}
							}
							downloadData(result, sceneObj.scene + tempName + '.jpg')
						})
					}
					correctBackground()
				}
			}
		})
	})()
	$('input[name="imageSize"]').keyup(function(e)                             {
	  if (/\D/g.test(this.value))
	  {
		// Filter non-digits from input value.
		this.value = this.value.replace(/\D/g, '')
	  }
	})
})

const downloadData = (dataUrl, filename) => {
  const link = document.createElement("a")
  link.href = dataUrl
  link.download = filename
  link.click()
}

/////////////////////////////////////////////////////////////// EMAIL
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

$(document).on('click', '#sendMyEmail', function() {

	(async () => {
		/* inputOptions can be an object or Promise */

		const inputOptions = {
			'corbin': 'Send to Corbin',
			'corbinyou': 'Send to Corbin & You',
			'you': 'Send just to you'
		  }

		// const inputOptions = new Promise((resolve) => {
		// 	setTimeout(() => {
		// 		console.log('+')
		// 		resolve({
		// 			'corbin': 'Send to Corbin',
		// 			'corbinyou': 'Send to Corbin & You',
		// 			'you': 'Send just to you'
		// 		})
		// 	}, 1000)
		// })
		const { value: formValues } = await Swal.fire({
			title: 'Send us a comment, question, request...',
			html: '<div class="my-input-email">Name</div> <input id="sendName" class="swal2-input swal22-input"><br />' +
				'<div class="my-input-email">Email</div> <input id="sendEmail" class="swal2-input swal22-input"><br />' +
				'<div class="my-input-email">Subject</div> <input id="sendSubject" class="swal2-input swal22-input"><br />' +
				'<div class="my-input-email"></div> <textarea id="sendBody" class="swal2-textarea" placeholder="Add your message here. An image and a link will be included in the email.">',
			focusConfirm: false,
			showCancelButton: true,
			input: 'radio',
			inputLabel: 'Send my email:',
			inputOptions: inputOptions,
			inputValidator: (radioValue) => {
				const sendName = document.getElementById('sendName').value
				const sendEmail = document.getElementById('sendEmail').value
				const sendSubject = document.getElementById('sendSubject').value
				const sendBody = document.getElementById('sendBody').value

				if ((!radioValue)||(!sendName)||(!sendEmail)||(!sendSubject)||(!sendBody)) {
					return 'Please complete all fields.'
				} else if (!sendEmail.includes('@')) {
					return 'Please add a valid email address.'
				} else {
					let link = $('#copy-link').html()
					sceneObj.sketchfabAPI.getScreenShot(500, 500, 'image/png', function (err, result) {
						$.ajax({
							type: "POST",
							url: "php/email.php?dbParam=" + sceneObj.dbParam + "&link=" + link + "&body=" + sendBody + "&subject=" + sendSubject + "&senderName=" + sendName + "&senderEmail=" + sendEmail + "&radio=" + radioValue + "&partGroup=" + sceneObj.partGroup,
							data: {"myDataURL":result},
							success: function(data) {
								console.log('success js ',data)
								Swal.fire({
								  position: 'center',
								  icon: 'success',
								  title: 'Email sent :) be sure to check your spam folder.',
								  showConfirmButton: false,
								  timer: 5000
								})
							},
							error: function(data) {
								console.log('error js ',data)
							}
						})
					})
				}
			}
		})
	})()
})