import { sceneObj } from './global_vars.js'
import { correctCameraConstraints, correctBackground, environmentScene, correctEnvironmentSettings, correctPostProcessingSettings } from './enviroment.js'
import { closeEditMenus } from './edit_global.js'
import { toTitleCase } from './global_popular_functions.js'

export function editEnviroment() {
	$('.configurator').append('<div id="environment">' +
	'<div id="environment-text" class="settings environment">Environment</div>' +
	'<div id="environment-box" class="box buttons"></div>' +
	'</div>')
}

$(document).on('click', '#environment-text', function() {
	closeEditMenus()
	$('.box').removeClass('buttons-active')
	$('#environment-box').addClass('buttons-active')
	$('#environment-box').append('<div class="environment-name">' + toTitleCase(sceneObj.myEnvironment) + '</div>' +
		'<div id="environment-update" class="environment-update">Update</div>' +

		'<div class="environment-setting">' +
		'<label for="renderer"><span class="highlight-environment">PBR / Matcap</span><span class="highlight">pbr</span></label>' +
		'<input class="css-input" type="text" id="renderer" name="renderer" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.renderer + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="shading"><span class="highlight-environment">Lit / Shadeless</span><span class="highlight">lit</span></label>' +
		'<input class="css-input" type="text" id="shading" name="shading" required minlength="1" maxlength="9" size="6" value="' + sceneObj.environmentObj.shading + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="udi"><span class="highlight-environment">image</span><span class="highlight-small">09190e8da7...</span></label>' + // 9190e8da70694ef3b9d1d0c01541917e
		'<input class="css-input" type="text" id="udi" name="udi" required minlength="1" maxlength="40" size="22" value="' + sceneObj.environmentObj.udi + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="bg1"><span class="highlight-environment">color 0 - 1</span><span class="highlight">0.95</span></label>' +
		'<input class="css-input" type="text" id="bg1" name="bg1" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.bg1 + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="bg2"><span class="highlight-environment">color 0 - 1</span><span class="highlight">0.95</span></label>' +
		'<input class="css-input" type="text" id="bg2" name="bg2" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.bg2 + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="bg3"><span class="highlight-environment">color 0 - 1</span><span class="highlight">0.95</span></label>' +
		'<input class="css-input" type="text" id="bg3" name="bg3" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.bg3 + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="fov"><span class="highlight-environment">fov 0 - 179</span><span class="highlight">45</span></label>' +
		'<input class="css-input" type="text" id="fov" name="fov" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.fov + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="zoom"><span class="highlight-environment">zoom</span><span class="highlight">true</span></label>' +
		'<input class="css-input" type="text" id="zoom" name="zoom" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.zoom + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="zoomIn"><span class="highlight-environment">zoomIn</span><span class="highlight">0.4</span></label>' +
		'<input class="css-input" type="text" id="zoomIn" name="zoomIn" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.zoomIn + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="zoomOut"><span class="highlight-environment">zoomOut</span><span class="highlight">2</span></label>' +
		'<input class="css-input" type="text" id="zoomOut" name="zoomOut" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.zoomOut + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="sharpenEnable"><span class="highlight-environment">sharpenEnable</span><span class="highlight">2</span></label>' +
		'<input class="css-input" type="text" id="sharpenEnable" name="sharpenEnable" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.sharpenEnable + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="sharpenFactor"><span class="highlight-environment">sharpenFactor</span><span class="highlight">2</span></label>' +
		'<input class="css-input" type="text" id="sharpenFactor" name="sharpenFactor" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.sharpenFactor + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="down"><span class="highlight-environment">down</span><span class="highlight">0</span></label>' +
		'<input class="css-input" type="text" id="down" name="down" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.down + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="up"><span class="highlight-environment">up</span><span class="highlight">1.5</span></label>' +
		'<input class="css-input" type="text" id="up" name="up" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.up + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="exposure"><span class="highlight-environment">exposure 0 - 35</span><span class="highlight">1</span></label>' + //(Brightness)
		'<input class="css-input" type="text" id="exposure" name="exposure" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.exposure + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="lightIntensity"><span class="highlight-environment">lightIntensity 0 - 5</span><span class="highlight">3</span></label>' +
		'<input class="css-input" type="text" id="lightIntensity" name="lightIntensity" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.lightIntensity + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="rotation"><span class="highlight-environment">rotation (Orientation) 0 - 360</span><span class="highlight">0</span></label>' +
		'<input class="css-input" type="text" id="rotation" name="rotation" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.rotation + '">' +
		'</div>' +

		'<div class="environment-setting">' +
		'<label for="blur"><span class="highlight-environment">blur 0 - 360</span><span class="highlight">0</span></label>' +
		'<input class="css-input" type="text" id="blur" name="blur" required minlength="1" maxlength="6" size="6" value="' + sceneObj.environmentObj.blur + '">' +
		'</div>')
})

$(document).on('click', '#environment-update', function() {
	sceneObj.environmentObj.renderer = $("#renderer").val()
	sceneObj.environmentObj.shading = $("#shading").val()
	sceneObj.environmentObj.udi = $("#udi").val()
	sceneObj.environmentObj.bg1 = $("#bg1").val()
	sceneObj.environmentObj.bg2 = $("#bg2").val()
	sceneObj.environmentObj.bg3 = $("#bg3").val()
	sceneObj.environmentObj.fov = $("#fov").val()
	sceneObj.environmentObj.zoom = $("#zoom").val()
	sceneObj.environmentObj.zoomIn = $("#zoomIn").val()
	sceneObj.environmentObj.zoomOut = $("#zoomOut").val()
	sceneObj.environmentObj.sharpenEnable = $("#sharpenEnable").val()
	sceneObj.environmentObj.sharpenFactor = $("#sharpenFactor").val()
	sceneObj.environmentObj.up = $("#up").val()
	sceneObj.environmentObj.exposure = $("#exposure").val()
	sceneObj.environmentObj.lightIntensity = $("#lightIntensity").val()
	sceneObj.environmentObj.rotation = $("#rotation").val()
	sceneObj.environmentObj.blur = $("#blur").val()
	let obj = JSON.stringify(sceneObj.environmentObj)
	correctCameraConstraints()
	correctBackground()
	environmentScene()
	correctEnvironmentSettings()
	correctPostProcessingSettings()
	$.ajax({
		type: "POST",
		url: "php/update/environment_update.php?dbParam=" + sceneObj.dbParam + "&my-environment=" + sceneObj.myEnvironment + "&obj-environment=" + obj,
		dataType: "json",
		success: function(json) {
			console.log('success',json)
		},
		error: function(json) {
			console.log('error')
		}
	})
})