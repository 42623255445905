import { sceneObj } from './global_vars.js'
import { formatTextTitle } from './global_popular_functions.js'
import { findMaterialPosition, updateMaterial, getTextures } from './textures_loading.js'
import { closeEditMenus } from './edit_global.js'
import { updateTexture } from './textures_update.js'

export function editTexture() {
	$('.configurator').append('<div id="texture">' +
		'</div>')
	$('#texture').append('<div id="texture-text" class="settings texture">Texture Settings</div>' +
		'<div id="texture-box" class="box buttons"></div>')
}

if (sceneObj.edit == 'yes') {
	$(document).on('click', '#texture-text', function() {
		let textureObj = sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture]
		addTextureData(textureObj)
	})

	$(document).on('click', '.thumb', function() {
		sceneObj.activeTexture = $(this).attr('id')
		let textureObj = sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture]
		let string = formatTextTitle(sceneObj.activeTexture)
		addTextureData(textureObj,string)
		$( "#texture-box" ).css('display', 'block')
	})
	$(document).on('click', '.thumb-stitch-pattern-color', function() {
		sceneObj.activeTexture = $(this).attr('id')
		let textureObj = sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture]
		let string = sceneObj.activeTexture
		addTextureData(textureObj,string)
		$( "#texture-box" ).css('display', 'block')
	})
}

function addTextureData(textureObj,str) {
	closeEditMenus()
	$('.box').removeClass('buttons-active')
	$('#texture-box').addClass('buttons-active')
	$('#texture-box').append('<div class="texture-name">' + str + '</div>' +
		'<div id="update-texture" class="texture-update">Update</div>' +

		'<div class="texture-setting">' +
		'<label for="texture_group"><span class="highlight-texture">texture_group</span><span class="edit-info">all</span></label>' +
		'<input class="css-input" type="text" id="texture_group" name="texture_group" required minlength="1" maxlength="5" size="5" value="' + textureObj.textureGroup + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="albedo"><span class="highlight-texture">albedo</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="albedo" name="albedo" required minlength="1" maxlength="5" size="5" value="' + textureObj.albedo + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="specular"><span class="highlight-texture">specular</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="specular" name="specular" required minlength="1" maxlength="5" size="5" value="' + textureObj.specular + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="metalness"><span class="highlight-texture">metalness</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="metalness" name="metalness" required minlength="1" maxlength="5" size="5" value="' + textureObj.metalness + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="scaleU"><span class="highlight-texture">scale w</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="scaleU" name="scaleU" required minlength="1" maxlength="5" size="5" value="' + textureObj.scaleU + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="scaleV"><span class="highlight-texture">scale h</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="scaleV" name="scaleV" required minlength="1" maxlength="5" size="5" value="' + textureObj.scaleV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="offsetU"><span class="highlight-texture">offset u</span><span class="edit-info">-1 to 1</span></label>' +
		'<input class="css-input" type="text" id="offsetU" name="offsetU" required minlength="1" maxlength="5" size="5" value="' + textureObj.offsetU + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="offsetV"><span class="highlight-texture">offset v</span><span class="edit-info">-1 to 1</span></label>' +
		'<input class="css-input" type="text" id="offsetV" name="offsetV" required minlength="1" maxlength="5" size="5" value="' + textureObj.offsetV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="rotation"><span class="highlight-texture">rotation</span><span class="edit-info">0 to 360</span></label>' +
		'<input class="css-input" type="text" id="rotation" name="rotation" required minlength="1" maxlength="4" size="5" value="' + textureObj.rotation + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="bump"><span class="highlight-texture">bump</span><span class="edit-info">0 to 10:</span></label>' +
		'<input class="css-input" type="text" id="bump" name="bump" minlength="0" maxlength="4" size="5" value="' + textureObj.bump + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="bumpName"><span class="highlight-texture">bump</span></label>' +
		'<input class="css-input" type="text" id="bumpName" name="bumpName" minlength="0" maxlength="100" size="20" value="' + textureObj.bumpName + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="bumpScaleU"><span class="highlight-texture">bump scale w</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="bumpScaleU" name="bumpScaleU" required minlength="1" maxlength="5" size="5" value="' + textureObj.bumpScaleU + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="bumpScaleV"><span class="highlight-texture">bump scale h</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="bumpScaleV" name="bumpScaleV" required minlength="1" maxlength="5" size="5" value="' + textureObj.bumpScaleV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheen"><span class="highlight-texture">sheen</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="sheen" name="sheen" minlength="0" maxlength="5" size="5" value="' + textureObj.sheen + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheenName"><span class="highlight-texture">sheen image</span></label>' +
		'<input class="css-input" type="text" id="sheenName" name="sheenName" minlength="0" maxlength="100" size="20" value="' + textureObj.sheenName + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheenRoughness"><span class="highlight-texture">sheen roughness</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="sheenRoughness" name="sheenRoughness" minlength="0" maxlength="5" size="5" value="' + textureObj.sheenRoughness + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheenTint0"><span class="highlight-texture">sheen tint </span><span class="edit-info">black = 0</span></label>' +
		'<input class="css-input" type="text" id="sheenTint0" name="sheenTint0" minlength="0" maxlength="5" size="5" value="' + textureObj.sheenTint0 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheenTint1"><span class="highlight-texture">sheen tint </span><span class="edit-info">white = 1</span></label>' +
		'<input class="css-input" type="text" id="sheenTint1" name="sheenTint1" minlength="0" maxlength="5" size="5" value="' + textureObj.sheenTint1 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="sheenTint2"><span class="highlight-texture">sheen tint </span><span class="edit-info">black = 0</span></label>' +
		'<input class="css-input" type="text" id="sheenTint2" name="sheenTint2" minlength="0" maxlength="5" size="5" value="' + textureObj.sheenTint2 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="glossiness"><span class="highlight-texture">glossiness</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="glossiness" name="glossiness" minlength="0" maxlength="4" size="5" value="' + textureObj.glossiness + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="glossinessName"><span class="highlight-texture">glossiness</span></label>' +
		'<input class="css-input" type="text" id="glossinessName" name="glossinessName" minlength="0" maxlength="100" size="20" value="' + textureObj.glossinessName + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="glossinessScaleU"><span class="highlight-texture">gloss scale w</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="glossinessScaleU" name="glossinessScaleU" required minlength="1" maxlength="5" size="5" value="' + textureObj.glossinessScaleU + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="glossinessScaleV"><span class="highlight-texture">gloss scale h</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="glossinessScaleV" name="glossinessScaleV" required minlength="1" maxlength="5" size="5" value="' + textureObj.glossinessScaleV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="roughness"><span class="highlight-texture">roughness</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="roughness" name="roughness" minlength="0" maxlength="4" size="5" value="' + textureObj.roughness + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="roughnessName"><span class="highlight-texture">roughness image</span></label>' +
		'<input class="css-input" type="text" id="roughnessName" name="roughnessName" minlength="0" maxlength="100" size="20" value="' + textureObj.roughnessName + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="roughnessScaleU"><span class="highlight-texture">rough scale w</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="roughnessScaleU" name="roughnessScaleU" required minlength="1" maxlength="5" size="5" value="' + textureObj.roughnessScaleU + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="roughnessScaleV"><span class="highlight-texture">rough scale h</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="roughnessScaleV" name="roughnessScaleV" required minlength="1" maxlength="5" size="5" value="' + textureObj.roughnessScaleV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="cavity"><span class="highlight-texture">cavity</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="cavity" name="cavity" minlength="0" maxlength="4" size="5" value="' + textureObj.cavity + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="cavityName"><span class="highlight-texture">cavity image</span></label>' +
		'<input class="css-input" type="text" id="cavityName" name="cavityName" minlength="0" maxlength="100" size="20" value="' + textureObj.cavityName + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="cavityScaleU"><span class="highlight-texture">cavity scale w</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="cavityScaleU" name="cavityScaleU" required minlength="1" maxlength="5" size="5" value="' + textureObj.cavityScaleU + '">' +
		'</div>' +
		'<div class="texture-setting">' +
		'<label for="cavityScaleV"><span class="highlight-texture">cavity scale h</span><span class="edit-info">-128 to 128</span></label>' +
		'<input class="css-input" type="text" id="cavityScaleV" name="cavityScaleV" required minlength="1" maxlength="5" size="5" value="' + textureObj.cavityScaleV + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="color0"><span class="highlight-texture">color</span><span class="edit-info">red 1</span></label>' +
		'<input class="css-input" type="text" id="color0" name="color0" minlength="0" maxlength="7" size="5" value="' + textureObj.color0 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="color1"><span class="highlight-texture">color</span><span class="edit-info">green 1</span></label>' +
		'<input class="css-input" type="text" id="color1" name="color1" minlength="0" maxlength="7" size="5" value="' + textureObj.color1 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="color2"><span class="highlight-texture">color</span><span class="edit-info">blue 1</span></label>' +
		'<input class="css-input" type="text" id="color2" name="color2" minlength="0" maxlength="7" size="5" value="' + textureObj.color2 + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="clearCoat"><span class="highlight-texture">clear coat</span><span class="edit-info">0 to 1</span></label>' +
		'<input class="css-input" type="text" id="clearCoat" name="clearCoat" minlength="0" maxlength="5" size="5" value="' + textureObj.clearCoat + '">' +
		'</div>' +

		'<div class="texture-setting">' +
		'<label for="textureType"><span class="highlight-texture">type</span><span class="edit-info">image or color</span></label>' +
		'<input class="css-input" type="text" id="textureType" name="textureType" required minlength="5" maxlength="5" size="5" value="' + textureObj.textureType + '">' +
		'</div>')

	$(document).on('click', '#update-texture', function() {
		let texture_group = $("#texture_group").val()
		let albedo = $("#albedo").val()
		let metalness = $("#metalness").val()
		let specular = $("#specular").val()
		let scaleU = $("#scaleU").val()
		let scaleV = $("#scaleV").val()
		let bump = $("#bump").val()
		let bumpName = $("#bumpName").val()
		let bumpScaleU = $("#bumpScaleU").val()
		let bumpScaleV = $("#bumpScaleV").val()
		let cavityScaleU = $("#cavityScaleU").val()
		let cavityScaleV = $("#cavityScaleV").val()
		let offsetU = $("#offsetU").val()
		let offsetV = $("#offsetV").val()
		let rotation = $("#rotation").val()
		let cavityName = $("#cavityName").val()
		let cavity = $("#cavity").val()
		let glossinessName = $("#glossinessName").val()
		let glossinessScaleU = $("#glossinessScaleU").val()
		let glossinessScaleV = $("#glossinessScaleV").val()
		let roughnessName = $("#roughnessName").val()
		let roughnessScaleU = $("#roughnessScaleU").val()
		let roughnessScaleV = $("#roughnessScaleV").val()
		let glossiness = $("#glossiness").val()
		let roughness = $("#roughness").val()
		let sheen = $("#sheen").val()
		let sheenName = $("#sheenName").val()
		let sheenTint0 = $("#sheenTint0").val()
		let sheenTint1 = $("#sheenTint1").val()
		let sheenTint2 = $("#sheenTint2").val()
		let sheenRoughness = $("#sheenRoughness").val()
		let clearCoat = $("#clearCoat").val()
		let color0 = $("#color0").val()
		let color1 = $("#color1").val()
		let color2 = $("#color2").val()
		let textureType = $("#textureType").val()

		console.log('sheen',sheen)
		console.log('sceneObj.activeTexture',sceneObj.activeTexture)
		console.log('sceneObj',sceneObj)

		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].texture_group = texture_group
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].albedo = albedo
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].metalness = metalness
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].specular = specular
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].scaleU = scaleU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].scaleV = scaleV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].bump = bump
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].bumpName = bumpName
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].bumpScaleU = bumpScaleU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].bumpScaleV = bumpScaleV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].cavityScaleU = cavityScaleU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].cavityScaleV = cavityScaleV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].offsetU = offsetU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].offsetV = offsetV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].rotation = rotation
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].cavityName = cavityName
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].cavity = cavity
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].glossinessName = glossinessName
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].glossinessScaleU = glossinessScaleU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].glossinessScaleV = glossinessScaleV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].roughnessName = roughnessName
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].roughnessScaleU = roughnessScaleU
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].roughnessScaleV = roughnessScaleV
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].glossiness = glossiness
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].roughness = roughness
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheen = sheen
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheenName = sheenName
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheenTint0 = sheenTint0
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheenTint1 = sheenTint1
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheenTint2 = sheenTint2
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].sheenRoughness = sheenRoughness
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].clearCoat = clearCoat
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].color0 = color0
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].color1 = color1
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].color2 = color2
		sceneObj.everyTexturesAttributesObj[sceneObj.activeTexture].textureType = textureType
		$.ajax({
			type: "POST",
			url: "php/update/texture_data.php?dbParam=" + sceneObj.dbParam + "&activeTexture=" + sceneObj.activeTexture +
				"&texture_group=" + texture_group +
				"&albedo=" + albedo +
				"&specular=" + specular +
				"&metalness=" + metalness +
				"&scaleU=" + scaleU +
				"&scaleV=" + scaleV +
				"&bump=" + bump +
				"&bumpName=" + bumpName +
				"&bumpScaleU=" + bumpScaleU +
				"&bumpScaleV=" + bumpScaleV +
				"&cavityScaleU=" + cavityScaleU +
				"&cavityScaleV=" + cavityScaleV +
				"&offsetU=" + offsetU +
				"&offsetV=" + offsetV +
				"&rotation=" + rotation +
				"&cavityName=" + cavityName +
				"&cavity=" + cavity +

				"&glossiness=" + glossiness +
				"&glossinessName=" + glossinessName +
				"&glossinessScaleU=" + glossinessScaleU +
				"&glossinessScaleV=" + glossinessScaleV +

				"&roughness=" + roughness +
				"&roughnessName=" + roughnessName +
				"&roughnessScaleU=" + roughnessScaleU +
				"&roughnessScaleV=" + roughnessScaleV +

				"&glossiness=" + glossiness +
				"&roughness=" + roughness +
				"&sheen=" + sheen +
				"&sheenName=" + sheenName +
				"&sheenTint0=" + sheenTint0 +
				"&sheenTint1=" + sheenTint1 +
				"&sheenTint2=" + sheenTint2 +
				"&sheenRoughness=" + sheenRoughness +
				"&clearCoat=" + clearCoat +
				"&color0=" + color0 +
				"&color1=" + color1 +
				"&color2=" + color2 +
				"&textureType=" + textureType,
			dataType: "json",
			success: function(json) {
				console.log(json, 'TEXTURE UPDATE')
				updateTexture(sceneObj.activeTexture, sceneObj.activeMaterial)
			},
			error: function(jqxhr) {
				console.log("error - " + jqxhr)
			}
		})
	})
}