// Options are the Seat options, heat, cool...
// Accessories are the backrests, saddle cream

import { sceneObj, params } from './global_vars.js'
import { selectedOptionsArray, updateMenu, updateOptionsArray } from './global.js'
import { menuHeaderColor } from './global_popular_functions.js'
import { setURL } from './url.js'
import { updateTexture } from './textures_update.js'
import { getOptionNotes } from './option_notes.js'
import { removeBackrests } from './backrests.js'

export function addOptionHead() {
	$('.configurator').append('<div id="option">' +
	'<div id="option-menu-head" class="menu-head" name="option">' +
	'Options' +
	'</div>' +
	'<div id="buttons-option" class="buttons">' +
	'</div>')
	sceneObj.myFormOptions = $('<div id=option-form></div>')
	let accessories = 0
	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		if ((sceneObj.scene != sceneObj.myOptionsArray[i].scene) && (accessories == 0)) {
			$('.configurator').append('<div id="accessories">' +
			'<div id="accessories-menu-head" class="menu-head" name="accessories">' +
			'Accessories' +
			'</div>' +
			'<div id="buttons-accessories" class="buttons">' +
			'</div>')
			sceneObj.myFormAccessories = $('<div id=accessories-form></div>')
			accessories++
		}
		let checked = ''
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
			checked = 'checked'
			sceneObj.activeOption = sceneObj.myOptionsArray[i].id
		}
		var myName = myOptionName(sceneObj.myOptionsArray[i].name)
		var myDescription = myOptionDescription(sceneObj.myOptionsArray[i].description)

		// Add shipping
		let price = 0
		// console.log('sceneObj.myOptionsArray[i].price', sceneObj.myOptionsArray[i].price)
		// console.log('sceneObj.myOptionsArray[i].shipping', sceneObj.myOptionsArray[i].shipping)
		price = Number(sceneObj.myOptionsArray[i].price) + Number(sceneObj.myOptionsArray[i].shipping)

		if (sceneObj.scene == sceneObj.myOptionsArray[i].scene) {
			// check to see if we are excluding: see prams. Example &switchheat=no
			if ((sceneObj.heatswitch == 'no')&&(sceneObj.myOptionsArray[i].pram == 'heat')) {
				// Don't add
			}else {
				sceneObj.myFormOptions.append('<div class="option-box">' +
					'<div class="option-box-title">' +
						'<label for="html">#' + myName + '</label>' +
					'</div>' +
					'<div class="option-box-info">' +
						'<p class="option-info">' + myDescription + '</p>' +
						'<p class="product-price">'+
						'<span class="dollar">$</span>' +
						Number(sceneObj.myOptionsArray[i].price) +
						'</p>' +
						'<input type="checkbox" value="' + sceneObj.myOptionsArray[i].id + '" name="' + sceneObj.myOptionsArray[i].group + '" ' + checked + '>' +
					'</div>' +
				'</div>')
			}
		}else {
			sceneObj.myFormAccessories.append('<div class="option-box" part="'+sceneObj.myOptionsArray[i].partGroup+'" data-order="'+ sceneObj.myOptionsArray[i].order +'">' +
				'<div class="option-box-title">' +
					'<label for="html">#' + myName + '</label>' +
				'</div>' +
				'<div class="option-box-info">' +
					'<p class="option-info">' + myDescription + '</p>' +
					'<p class="product-price">'+
					'<span class="dollar">$</span>' +
					Number(price) +
					'</p>' +
					'<input type="checkbox" value="' + sceneObj.myOptionsArray[i].id + '" name="' + sceneObj.myOptionsArray[i].group + '" ' + checked + '>' +
				'</div>' +
			'</div>')
		}
		// console.log('price',price)
	}
	plateOptions()
}



$(document).on('click', '#option-menu-head', function() {
	addOptionThumbs('option')
	sceneObj.activeMaterial = 'option'
	getCheckedValues('camera')
	menuHeaderColor()
})


$(document).on('click', '#accessories-menu-head', function() {
	addOptionThumbs('accessories')
	sceneObj.activeMaterial = 'accessories'
	getCheckedValues('camera')
	menuHeaderColor()
})

function getCheckedValues(update) {
	let array = []
	// array - the selected items
	let checkedValues = $('input:checkbox:checked').map(function() {
		array.push(this.value)
	}).get()
	if (update == 'camera') {
		// selectedOptionsArray('id')[0] the id of the clicked box
		iterateOverObject(selectedOptionsArray('id')[0])
	} else {
		updateOptionsArray(array)
		setURL()
	}
	plateOptions()
}

// Only allow one checked per group
// Do nothing if no group
// *** We might have to trigger a camera angle here for some options?
// Will do this when we have a suitable option
$(document).on('click', 'input:checkbox', function() {
	// This code does not let you unselect all grouped boxes!
	let group = $(this).attr("name")
	if (group == '') {} else {
		if (!group.includes('*')) {
			let groupCheck = "input:checkbox[name='" + $(this).attr("name") + "']"
			$(groupCheck).prop('checked', false)
			$(groupCheck).removeAttr("checked")
			$(this).prop('checked', true)
		} else {
			if ($(this).is(':checked')) {
				let groupCheck = "input:checkbox[name='" + $(this).attr("name") + "']"
				$(groupCheck).prop('checked', false)
				$(groupCheck).removeAttr("checked")
				$(this).prop('checked', true)
			} else {
				// console.log('not checked')
			}
		}
	}
		getCheckedValues()
		removeBackrests()

		iterateOverObject(this.value)
		sceneObj.activeOption = this.value
		getOptionNotes()
})

export function myOptionName(optionName) {
	var html = ""
	html = optionName.replace('*','<span class="heat">')
	html = html.replace('Heat ', 'Heat')
	html = html.replace('*','</span>')
	html = html.replace('-PASSENGER','')
	html = html.replace('& Cool','</span><span class="and"> & </span><span class="cool"> Cool </span>')
	return html
}

export function myOptionDescription(DescriptionText) {
	var html = ""
	html = DescriptionText.replace('*','<span class="my-option-description">')
	html = html.replace('*','</span>')
	return html
}

function addOptionThumbs(name) {
	if (name == 'option') {
		$('.buttons').empty()
		$('#buttons-option').append(sceneObj.myFormOptions)
		$('#buttons-accessories').append(sceneObj.myFormAccessories)
		$('#buttons-option').css('display', 'inherit')
		$('#buttons-accessories').css('display', 'none')
	} else if (name == 'accessories') {
		$('.buttons').empty()
		$('#buttons-option').append(sceneObj.myFormOptions)
		$('#buttons-accessories').append(sceneObj.myFormAccessories)
		$('#buttons-option').css('display', 'none')
		$('#buttons-accessories').css('display', 'inherit')

		// Order the divs based on order value in data base, HTML: data-order="X"
		var $wrapper = $('#accessories-form');

		$wrapper.find('.option-box').sort(function (a, b) {
			return +a.dataset.part - +b.dataset.part;
		}).appendTo( $wrapper );

		// Lets add a header to each group, backrest, passenger-backrest, accessories
		$("#accessories-sub-headers-rider").remove();
		$("#accessories-sub-headers-passenger").remove();
		$('#accessories-form').find("[part='backrest']").eq(0).before('<div id="accessories-sub-headers-rider" class="accessories-sub-headers">Rider Backrests</div>');
		$('#accessories-form').find("[part='backrest-passenger']").eq(0).before('<div id="accessories-sub-headers-passenger" class="accessories-sub-headers">Passenger Backrests</div>');
		//$('#accessories-form').find("[part='accessories']").eq(0).before('<div class="material-type">Accessories</div><div class="fade-line">');
	}
}

function iterateOverObject(myID) {
	sceneObj.myOptionsArray.forEach((element, index, array) => {
		if (element.id == myID) {
			if (sceneObj.myOptionsArray[index].positionX != 0) {
				mySetCameraLookAt(index)
				sceneObj.sketchfabAPI.setCameraLookAtEndAnimationCallback(function(err) {
					if (!err) {
						sceneObj.sketchfabAPI.getCameraLookAt(function(err, camera) {
							let a = Math.round((sceneObj.myOptionsArray[index].positionX * sceneObj.myResize + Number.EPSILON) * 100) / 100
							let b = Math.round((camera.position[0] + Number.EPSILON) * 100) / 100
							if (a != b) {
								mySetCameraLookAt(index)
							}
						})
					}
				})
			}
			return index
		}
	})
}

function mySetCameraLookAt(index) {
	if (sceneObj.activeMaterial == 'accessories') {
		// Do nothing
	}else {
		sceneObj.sketchfabAPI.setCameraLookAt([
			sceneObj.myOptionsArray[index].positionX * sceneObj.myResize,
			sceneObj.myOptionsArray[index].positionY * sceneObj.myResize,
			sceneObj.myOptionsArray[index].positionZ * sceneObj.myResize
		], [
			sceneObj.myOptionsArray[index].targetX,
			sceneObj.myOptionsArray[index].targetY,
			sceneObj.myOptionsArray[index].targetZ
		], sceneObj.cameraSpeed, function(err) {})
	}
}

// HACK 02-SB is the only plate that excepts chrome_plate
// Is backrest selected? If no then hide plate
// Is backrest selected? If yes, if 02-SB is not selected change chrome_plate
function plateOptions() {

	// Make values default
	sceneObj.backrest.rider = 'none'
	sceneObj.backrest.passenger = 'none'
	sceneObj.backrest.chrome = 'none'

	// Run through options
	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		// If selected
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
			if(sceneObj.myOptionsArray[i].partGroup == 'backrest'){
				sceneObj.backrest.rider = sceneObj.myOptionsArray[i].name
			}
			if(sceneObj.myOptionsArray[i].partGroup == 'backrest-passenger'){
				sceneObj.backrest.passenger = sceneObj.myOptionsArray[i].name
			}
		}
	}


	// Do we have 02-SB selected in all available backrests
	if (sceneObj.backrest.rider == '02-SB' || sceneObj.backrest.rider == 'none' || sceneObj.partGroup == '02-SB') {
		if (sceneObj.backrest.passenger == '02-SB-PASSENGER' || sceneObj.backrest.passenger == 'none' || sceneObj.partGroup == '02-SB') {
			if (sceneObj.backrest.rider == 'none' && sceneObj.backrest.passenger == 'none' && sceneObj.partGroup != '02-SB') {
				// Do nothing, no backrests
			}else {
				sceneObj.backrest.chrome = 'available'
			}
		}
	}

	if (sceneObj.backrest.chrome == 'none'){
		// Look for chrome if it is selected remove chrome
		for (let x = 0; x < sceneObj.myTexturesArray.length; x++) {
			if (sceneObj.myTexturesArray[x] == 'chrome_plate') {
				sceneObj.myTexturesArray[x] = 'black_plate'
				sceneObj.activeTexture = 'black_plate'
				sceneObj.activeMaterial = 'plate'
				updateTexture(sceneObj.activeTexture, sceneObj.activeMaterial)
				updateMenu()
				setURL()
			}
		}
	}

	// Do we have any backrests? If no hide the plate menu
	if (sceneObj.backrest.rider == 'none' && sceneObj.backrest.passenger == 'none') {
		$('#plate').hide()
	}else {
		$('#plate').show()
	}
	// Backrest only
	if ((sceneObj.scene == 'GB') || (sceneObj.scene == '02-G') || (sceneObj.scene == '02-L') || (sceneObj.scene == '02-SB') || (sceneObj.scene == '02-T') || (sceneObj.scene == '297-SB') || (sceneObj.scene == 'BR') || (sceneObj.scene == '297-SB-LARGE') || (sceneObj.scene == '297-SB-SMALL')) {
		console.log('plate show')
		$('#plate').show()
	}
}
