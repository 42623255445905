import { sceneObj } from './global_vars.js'
import { toTitleCase } from './global_popular_functions.js'

// We have an option to not show the stitching_assembly
// When we have received all of the data from the database
// we check to see of the option = no
// if it does we need to remove the option from the myMaterialsArray & myTexturesArray
export function stitching_assembly() {
	// console.log('sceneObj.myMaterialsArray 1', sceneObj.myMaterialsArray)
	// console.log('sceneObj.myMaterialsArray 1', sceneObj.myTexturesArray)
	// console.log('sceneObj.myOptionsArray[0]',sceneObj.myOptionsArray[0])
	if (toTitleCase(sceneObj.myOptionsArray[0].stitching_assembly) == 'Yes') {
		sceneObj.stitchingAssembly = 'yes'
		// console.log('sceneObj.stitchingAssembly = yes')
		// if we can drape stitching_assembly then we need to remove it from the staticMaterialArray
		for (let x = 0; x < sceneObj.staticMaterialArray.length; x++) {
			if (sceneObj.staticMaterialArray[x] == 'stitching_assembly') {
				sceneObj.staticMaterialArray.splice(x, 1)
				sceneObj.staticTextureArray.splice(x, 1)
			}
		}
	}else {
		sceneObj.stitchingAssembly = 'no'
	}
	if (sceneObj.stitchingAssembly == 'no') {
		let index = sceneObj.myMaterialsArray.indexOf("stitching_assembly")
		// console.log('index', index)
		if (index < 0) {
			// do nothing stitching_assembly not there
		}else {
			let count = sceneObj.myMaterialsArray.length
			// console.log('count', count)
			// console.log('sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			if (sceneObj.myTexturesArray.length < count) {
				// console.log('less than', count)
				sceneObj.myMaterialsArray.splice(index, 1)
				// console.log('sceneObj.myMaterialsArray', sceneObj.myMaterialsArray)
				// console.log('sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			}else {
				// console.log('same as', count)
				sceneObj.myMaterialsArray.splice(index, 1)
				sceneObj.myTexturesArray.splice(index, 1)
				// console.log('sceneObj.myMaterialsArray', sceneObj.myMaterialsArray)
				// console.log('sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			}
		}
	}
	// However if we get from URL prams we might need to add sceneObj.myTexturesArray
	if (sceneObj.stitchingAssembly == 'yes') {
		let index = sceneObj.myMaterialsArray.indexOf("stitching_assembly")
		// console.log('index', index)
		if (index < 0) {
			// do nothing stitching_assembly not there
		}else {
			let count = sceneObj.myMaterialsArray.length
			// console.log('count', count)
			// console.log('sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			if (sceneObj.myTexturesArray.length < count) {
				// console.log('less than', count)
				sceneObj.myTexturesArray.splice(index, 0, 'black')
				// console.log('sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			}else {
				//console.log('else sceneObj.myTexturesArray', sceneObj.myTexturesArray)
			}
		}
	}
}