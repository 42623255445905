import { sceneObj } from './global_vars.js'
import { formatTextTitle, menuHeaderColor } from './global_popular_functions.js'
import { sortArrays, powerOfTwo } from './global.js'
import { gotoCameraPosition } from './camera.js'
import { setURL } from './url.js'
import { hideShowMenu } from './global.js'
import { playAnimation } from './animation.js'
let orderLogoArray = []
let colorArray = []
let logoLayerNameArray = []
let allStitchColorsArray = []
let maskArray = ['logo_0', 'logo_1', 'logo_2', 'logo_3']


// Called on load
// Build the menu
export function logoBuildMenu() {
	logoObjToArray()
	let lastElement = sceneObj.myMaterialsArray[sceneObj.myMaterialsArray.length - 1]
	$('#' + lastElement).after('<div id="logo">' +
		'<div id="logo-menu-head" class="menu-head" name="logo">' +
		'logo' +
		'<img class="menu-image" src="' + sceneObj.texturesFolder + 'logos/thumbs/' + sceneObj.logo + '.png">' +
		'<div class="menu-text">' + formatTextTitle(sceneObj.logo) + '</div>' +
		'</div>' +
		'<div id="buttons-logo" class="buttons"></div>' +
		'<div id="buttons-logo-container"></div>' +
		'</div>')
	stitchInOrder()
	logoLoadDefaultTextures()
}

// Add the logo design thumbs
function logoAddThumbs() {
	if (sceneObj.menuOpen == 'logo') {
		$('.buttons').empty();
		$('.buttons-active').removeClass('buttons-active');
		$('.menu-head').removeClass('menu-active')
		sceneObj.menuOpen = 100
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").hide();
		}
	}else {
		$('.buttons').empty()
		for (let i = 0; i < orderLogoArray[1].length; i++) {
			let tempName = formatTextTitle(orderLogoArray[1][i])

			// Filter out logos
			let PowerArray = powerOfTwo(sceneObj.logoKey)

			if (jQuery.inArray(Number(orderLogoArray[2][i]), PowerArray) !== -1) {
				const toolTipHTML = "<img src='" + sceneObj.texturesFolder + "logos/mouseover/" + orderLogoArray[1][i] + "."+sceneObj.preferencesObj.mouseoverExtension+"'>" + tempName
				$('#buttons-logo').append('<div data-tippy-content="' + toolTipHTML + '" class="thumb-logo active toolTexture" id="' + orderLogoArray[1][i] + '" style="background-image: url(' + sceneObj.texturesFolder + 'logos/thumbs/' + orderLogoArray[1][i] + '.png")"></div>')
			} else {
				// console.log('do not show logo: ' + orderLogoArray[1][i])
			}
		}
		addLogoColorMenus()
		sceneObj.menuOpen = 'logo'
		if (sceneObj.preferencesObj.menuButtonUp == 'yes') {
			$("#menu-icon-up-button").show();
		}
	}
}

function addLogoColorMenus() {
	if ($(".logo-color-menu").length) {
		$(".logo-color-menu").remove()
	}

	if ($('#logo-spacer').length == 0) {
		//it doesn't exist
		$('#buttons-logo').append('<div id="logo-spacer"></div>')
	}

	//reverse loop, corbin top
	for (let i = colorArray.length - 1; i >= 0; i--) {
		if ((colorArray[i] != 'image') && (colorArray[i] != 'none')) {
			$('#buttons-logo').append('<div class="logo-color-menu" id="logo-color-' + i + '">' +
				'<div class="menu-head-logo-thumbs menu-active" name="logo-color-' + i + '">' +
				logoLayerNameArray[i] + '<span class="logo-text">Logo Color </span>' +
				'<img class="menu-image" src="' + sceneObj.texturesFolder + 'textures/thumb/' + colorArray[i] + '.png">' +
				'<div class="menu-text">' + formatTextTitle(colorArray[i]) + '</div>' +
				'</div>' +
				'<div  id="buttons-logo-' + i + '" class="buttons-logo buttons-active" name="' + i + '"></div>' +
				'</div>')
			let name = 'layer_' + i
			sceneObj.activeTexture = sceneObj.logosObj[sceneObj.logo][name]
			logoColorAddThumbs(i)
		}
	}
	if (sceneObj.touchScreen == false) {
		tippy('.toolTexture', {
			content: 'Tooltip',
			allowHTML: true,
			theme: 'material',
			placement: 'left',
		})
	}
	updateLogoDesignTick()
}

// Add thumbs to Logo color menu(s)'LogoColor-3'
function logoColorAddThumbs(position) {
	for (let x = 0; x < allStitchColorsArray.length; x++) {
		$('#buttons-logo-' + position).append('<div class="thumb-logo-color active" id="' + allStitchColorsArray[x] + '" style="background-image: url(' + sceneObj.texturesFolder + 'textures/thumb/' + allStitchColorsArray[x] + '.png")"></div>')
	}
	updateLogoColorTick(position)
}

// Click Logo Designs Menu
$(document).on('click', '#logo-menu-head', function() {
	sceneObj.activeMaterial = $(this).parent().attr('id')
	logoMenuClick(sceneObj.activeMaterial)
})

export function logoMenuClick(name) {
	// need to add this as we have added advent click (green)
	sceneObj.activeMaterial = name
	playAnimation(name)
	menuHeaderColor()
	logoAddThumbs()
	gotoCameraPosition('logo')
	highlightUpdateMaterial('', 'logo_3')
}

async function highlightUpdateMaterial(index, name) {
	try {
		// Convert the callback-based function to a Promise
		const materials = await new Promise((resolve, reject) => {
			sceneObj.sketchfabAPI.getMaterialList((err, materials) => {
				if (err) {
					reject(err);
				} else {
					resolve(materials);
				}
			});
		});

		// Find the material position
		let materialPosition = materials.findIndex(material => material.name === name);

		if (materialPosition === -1) {
			throw new Error(`Material "${name}" not found`);
		}

		// Highlight the material
		await sceneObj.sketchfabAPI.highlightMaterial(materials[materialPosition]);
	} catch (error) {
		console.error("Error updating material:", error);
	}
}

// Click Logo Design Thumbs
$(document).on('click', '#buttons-logo .thumb-logo', function() {
	sceneObj.logo = $(this).attr('id')
	logoLoadDefaultTextures()
	//logoAddThumbs()
	//updateLogoDesignTick()
	addLogoColorMenus()
	updateLogoMenus()
	setURL()
	// Hide menu on small screens
	if (sceneObj.windowWidth < 700) {
		hideShowMenu()
	}
})

// Click Logo Color Thumbs
$(document).on('click', '.thumb-logo-color', function() {
	sceneObj.activeTexture = $(this).attr('id') // white
	let myPosition = $(this).parent('div').attr('name') // 1
	updateLogoObj(myPosition)
	updateLogoColorTick(myPosition)
	logoLoadDefaultTextures()
	updateLogoMenus()
	setURL()
	// Hide menu on small screens
	if (sceneObj.windowWidth < 700) {
		hideShowMenu()
	}
})

// Update menu text and small image
function updateLogoMenus() {
	$('#logo').children('div').children('.menu-text').html(formatTextTitle(sceneObj.logo))
	$('#logo').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'logos/thumbs/' + sceneObj.logo + '.png')
	if ((sceneObj.logosObj[sceneObj.logo].layer_0 != 'image') && (sceneObj.logosObj[sceneObj.logo].layer_0 != 'none')) {
		$('#logo-color-0').children('div').children('.menu-text').html(formatTextTitle(sceneObj.logosObj[sceneObj.logo].layer_0))
		$('#logo-color-0').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.logosObj[sceneObj.logo].layer_0 + '.png')
	}
	if ((sceneObj.logosObj[sceneObj.logo].layer_1 != 'image') && (sceneObj.logosObj[sceneObj.logo].layer_1 != 'none')) {
		$('#logo-color-1').children('div').children('.menu-text').html(formatTextTitle(sceneObj.logosObj[sceneObj.logo].layer_1))
		$('#logo-color-1').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.logosObj[sceneObj.logo].layer_1 + '.png')
	}
	if ((sceneObj.logosObj[sceneObj.logo].layer_2 != 'image') && (sceneObj.logosObj[sceneObj.logo].layer_2 != 'none')) {
		$('#logo-color-2').children('div').children('.menu-text').html(formatTextTitle(sceneObj.logosObj[sceneObj.logo].layer_2))
		$('#logo-color-2').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.logosObj[sceneObj.logo].layer_2 + '.png')
	}
	if ((sceneObj.logosObj[sceneObj.logo].layer_3 != 'image') && (sceneObj.logosObj[sceneObj.logo].layer_3 != 'none')) {
		$('#logo-color-3').children('div').children('.menu-text').html(formatTextTitle(sceneObj.logosObj[sceneObj.logo].layer_3))
		$('#logo-color-3').children('div').children('.menu-image').attr("src", sceneObj.texturesFolder + 'textures/thumb/' + sceneObj.logosObj[sceneObj.logo].layer_3 + '.png')
	}
}

// Update the tick
function updateLogoColorTick(position) {
	$('#buttons-logo-' + position).children('.thumb-logo-color').empty()
	$('#buttons-logo-' + position).children('#' + sceneObj.activeTexture).append('<img src="' + sceneObj.texturesFolder + 'config/tick.png ">')
}


function updateLogoObj(myPosition) {
	let str = 'layer_' + myPosition
	sceneObj.logosObj[sceneObj.logo][str] = sceneObj.activeTexture
}

function stitchInOrder() {
	// Get all stitch colors in order
	allStitchColorsArray = []
	for (let i = 0; i < sceneObj.orderTexturesGroupArray[2].length; i++) {

		// We run through the texture_group and see if "stitching" is there. If it is we add it.
		let currentArray = sceneObj.orderTexturesGroupArray[2][i];

		// Ensure the currentArray is an array
		if (typeof currentArray === 'string') {
			currentArray = currentArray.split(',').map(item => item.trim());
		}
		// Now you can check for the exact match
		if (currentArray.includes('stitching') &&
			currentArray.some(value => value === 'stitching')) {
			// True only if it contains "stitching" as an exact match
			allStitchColorsArray.push(sceneObj.orderTexturesGroupArray[1][i])
		}
	}
}

function logoObjToArray() {
	let nameArray = []
	let orderArray = []
	let myKey = []
	Object.entries(sceneObj.logosObj).forEach(item => {
		nameArray.push(item[1].logo_name)
		orderArray.push(item[1].my_order)
		myKey.push(item[1].my_key)
	})
	let myArray = sortArrays([orderArray, nameArray, myKey])
	orderLogoArray = myArray
}

function updateLogoDesignTick() {
	$('.thumb-logo').empty()
	$('#' + sceneObj.logo).append('<img src="' + sceneObj.texturesFolder + 'config/tick.png ">')
}

// Called on load
// Loop through the 4 logo mask layers
// Add the default or URL assets
function getLogoData() {

	// ['white', 'none', 'none', 'none']
	colorArray = []

	// ['corbin', 'none', 'none', 'none']
	logoLayerNameArray = []

	Object.entries(sceneObj.logosObj).forEach(item => {
		if (item[0] == sceneObj.logo) {
			colorArray.push(item[1].layer_0)
			colorArray.push(item[1].layer_1)
			colorArray.push(item[1].layer_2)
			colorArray.push(item[1].layer_3)

			logoLayerNameArray.push(item[1].name_0)
			logoLayerNameArray.push(item[1].name_1)
			logoLayerNameArray.push(item[1].name_2)
			logoLayerNameArray.push(item[1].name_3)
		}
	})
}

function logoLoadDefaultTextures() {
	getLogoData()
	loadAllLogos()
}

// Use sleep for testing
const sleep = ms => {
	return new Promise(resolve => setTimeout(resolve, ms))
}
const runMainFunction = index => {
	return sleep(0).then(v => loadLogoImages(index))
}
const loadAllLogos = async _ => {
	//console.log('# START - Load Logo ################')
	for (let index = 0; index < maskArray.length; index++) {
		await runMainFunction(index)
		//console.log('# AWAIT #################')
	}
	//console.log('# END ###############')
}

function removeSpaces(str) {
	return str.replace(/ /g, '')
}

// Look to see if we have the logo mask (for each loop)
// Get each texture Uid
function loadLogoImages(myCount) {
	sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
		let found = 'no'
		let imageAddress = sceneObj.texturesFolder + 'logos/designs/' + sceneObj.logo + '/' + maskArray[myCount] + '.png'
		if (removeSpaces(colorArray[myCount]) == 'none') {
			imageAddress = sceneObj.texturesFolder + 'logos/designs/_blank/' + maskArray[myCount] + '.png'
		}
		if (!err) {
			textures.every(element => {
				if (element.name == imageAddress) {
					found = 'yes'
					textureUid = element.uid
					logosLoadTextures(textureUid, myCount)
					return !(el === 1)
				}
			})
			if (found != 'yes') {
				sceneObj.sketchfabAPI.addTexture(imageAddress, function(err, textureUid) {
					logosLoadTextures(textureUid, myCount)
				})
			}
		}
	})
}

// Now we have the texture Uid for the mask
// 1. Add the AlphaMask
// 2. Look for the stitching bump texture Uid
// 3. Update the materials, Bump, Color, Mask
function logosLoadTextures(textureUid, myCount) {
	sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
		if (!err) {
			for (let i = 0; i < materials.length; i++) {
				if (materials[i].name == maskArray[myCount]) {
					materials[i].channels.AlphaMask = {
						enable: true,
						factor: 0.5,
						invert: false,
						texture: {
							internalFormat: "ALPHA",
							magFilter: "LINEAR",
							minFilter: "LINEAR_MIPMAP_LINEAR",
							texCoordUnit: 0,
							textureTarget: "TEXTURE_2D",
							uid: textureUid,
							wrapS: "REPEAT",
							wrapT: "REPEAT",
						},
						UVTransforms: {
							offset: [0, 0],
							rotation: 0,
							scale: [1, 1],
						},
					}
					// Get color of logo layer
					let myUdi = ''
					// We have a color to apply
					if ((colorArray[myCount] != 'none') && (colorArray[myCount] != 'image')) {
						let tempNumber = i
						sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
							if (!err) {
								textures.forEach(element => {
									let getName = element.name.split("/")
									let getResult = getName[getName.length - 1] // Or parts.pop()
									getResult = getResult.slice(0, -4)
									if (getResult == 'stitch_bump') {
										myUdi = element.uid
										materials[tempNumber].channels.BumpMap = {
											enable: true,
											factor: 1,
											texture: {
												internalFormat: 'RGB',
												magFilter: 'LINEAR',
												minFilter: 'LINEAR_MIPMAP_LINEAR',
												texCoordUnit: 0,
												textureTarget: 'TEXTURE_2D',
												uid: myUdi,
												wrapS: 'REPEAT',
												wrapT: 'REPEAT'
											},
											UVTransforms: {
												offset: [0, 0],
												rotation: 0,
												scale: [0, 0],
												enable: true,
												factor: 1
											},
										}
										materials[tempNumber].channels.AlbedoPBR = {
											enable: true,
											factor: 1,
											color: {
												0: 0,
												0: 0,
												0: 0,
											},
										}
										//sceneObj.activeTexture = colorArray[myCount]
										let textureObj = sceneObj.everyTexturesAttributesObj[colorArray[myCount]]

										materials[tempNumber].channels.AlbedoPBR.color[0] = textureObj.color0
										materials[tempNumber].channels.AlbedoPBR.color[1] = textureObj.color1
										materials[tempNumber].channels.AlbedoPBR.color[2] = textureObj.color2

										materials[tempNumber].channels.BumpMap.enable = true
										materials[tempNumber].channels.BumpMap.factor = textureObj.bump
										materials[tempNumber].channels.BumpMap.texture.uid = myUdi

										//  *10 to the bump scale as we are using the stitching color
										// which is at a different scale to the logos.
										// A hack but a simple one that works :)
										materials[tempNumber].channels.BumpMap.UVTransforms.scale[0] = (textureObj.bumpScaleU * 10)
										materials[tempNumber].channels.BumpMap.UVTransforms.scale[1] = (textureObj.bumpScaleV * 10)
										materials[tempNumber].channels.BumpMap.UVTransforms.offset[0] = textureObj.offsetU
										materials[tempNumber].channels.BumpMap.UVTransforms.offset[1] = textureObj.offsetV
										materials[tempNumber].channels.BumpMap.UVTransforms.rotation = textureObj.rotation

										materials[tempNumber].channels.AlphaMask.enable = true
										materials[tempNumber].channels.AlphaMask.factor = 0.5
										materials[tempNumber].channels.AlphaMask.invert = false
										materials[tempNumber].channels.AlphaMask.texture.internalFormat = 'alpha'
										materials[tempNumber].channels.AlphaMask.texture.uid = textureUid
										sceneObj.sketchfabAPI.setMaterial(materials[tempNumber], function() {})
									}
								})
							}
						})
					}
					if ((colorArray[myCount] == 'image')) {
						// We have an image, no draping
						let tempNumber = i
						sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
							let imageFound = 'no'
							if (!err) {
								textures.every(element => {
									// console.log(element.name) // sceneObj.texturesFolder + logos/designs/corbin/logo0.png
									let getName = element.name.split("/")
									let getResult = getName[getName.length - 1] // Or parts.pop()
									getResult = getResult.slice(0, -4)
									if (getResult == sceneObj.logo) {
										myUdi = element.uid
										imageFound = 'yes'
										return !(el === 1)
									}
								})
								if (imageFound != 'yes') {
									sceneObj.sketchfabAPI.addTexture(sceneObj.texturesFolder + 'logos/designs/' + sceneObj.logo + '/' + maskArray[myCount] + '.png', function(err, textureUid) {})
								}
								materials[tempNumber].channels.AlbedoPBR = {
									enable: true,
									factor: 1,
									texture: {
										internalFormat: 'RGB',
										magFilter: 'LINEAR',
										minFilter: 'LINEAR_MIPMAP_LINEAR',
										texCoordUnit: 0,
										textureTarget: 'TEXTURE_2D',
										uid: textureUid,
										wrapS: 'REPEAT',
										wrapT: 'REPEAT'
									},
									UVTransforms: {
										offset: [0, 0],
										rotation: 0,
										scale: [1, 1],
										enable: true,
										factor: 1
									},
								}
								materials[tempNumber].channels.AlbedoPBR.texture.uid = textureUid
								sceneObj.sketchfabAPI.setMaterial(materials[tempNumber], function() {})
							}
						})
					}
					if (colorArray[myCount] == 'none') {
						// If nothing we still need to apply a blank png
						materials[i].channels.AlphaMask.enable = true
						materials[i].channels.AlphaMask.factor = 0.5
						materials[i].channels.AlphaMask.invert = false
						materials[i].channels.AlphaMask.texture.internalFormat = 'alpha'
						materials[i].channels.AlphaMask.texture.uid = textureUid
						sceneObj.sketchfabAPI.setMaterial(materials[i], function() {})
					}
					break
				}
			}
		}
	})
}