import { sceneObj } from './global_vars.js'
import { logoMenuClick } from './logo.js'
import { loadStitchPatternThumbs } from './stitch_pattern.js'
import { playAnimation } from './animation.js'
import { updateTick, createToolTipHTML, addThumbs } from './textures_and_textures_loading.js'
import { menuHeaderColor, includesSubstringCaseInsensitive, isInvalid } from './global_popular_functions.js'
import { gotoCameraPosition } from './camera.js'
import { stitchMenuClick } from './stitch_pattern.js'

export async function highlightUpdateMaterial(index, name) {
	const myLogo = includesSubstringCaseInsensitive(name, 'logo')
	const myStitch = includesSubstringCaseInsensitive(name, 'stitching_pattern')
	if (myLogo == true) {
		// We have clicked the logo, we have to do something different for the logo
		logoMenuClick('logo')
	} else if (myStitch == true) {
		stitchMenuClick('stitch-pattern')
	} else {
		const result = isInvalid(index);
		if (result == false) {
			sceneObj.activeMaterial = name
			sceneObj.activeTexture = sceneObj.myTexturesArray[sceneObj.myMaterialsArray.indexOf(sceneObj.activeMaterial)]
		}
		try {
			// Convert the callback-based function to a Promise
			const materials = await new Promise((resolve, reject) => {
				sceneObj.sketchfabAPI.getMaterialList((err, materials) => {
					if (err) {
						reject(err);
					} else {
						resolve(materials);
					}
				});
			});

			// Find the material position
			let materialPosition = materials.findIndex(material => material.name === sceneObj.activeMaterial);

			if (materialPosition === -1) {
				throw new Error(`Material "${sceneObj.activeMaterial}" not found`);
			}
			// Highlight the material
			await sceneObj.sketchfabAPI.highlightMaterial(materials[materialPosition]);

			// Some things we just ignore, example screws, inside, sometimes stitching...

			if (result == false) {
				playAnimation(name)
				menuHeaderColor()
				addThumbs(index)
				updateTick()
				gotoCameraPosition(name)
				createToolTipHTML()
			}

		} catch (error) {
			console.error("Error updating material:", error);
		}
	}


}