import { sceneObj } from './global_vars.js'

import { closeEditMenus } from './edit_global.js'


export function getData() {
	$('.configurator').append('<div id="get-data">' +
	'<div id="get-text" class="settings order">Get Data</div>' +
	'<div id="get-box" class="box buttons"></div>' +
	'</div>')
}
$(document).on('click', '#get-data', function() {
	closeEditMenus()
	$('#get-box').append('<div id="get-sceneObj" class="button-one">Get sceneObj</div>'+
	'<div id="get-materials" class="button-one">List Materials</div>' +
	'<div id="get-textures" class="button-one">List Textures</div>'+
	'<div class="space"></div>')

	$('.box').removeClass('buttons-active')
	$('#get-box').addClass('buttons-active')
})

$(document).on('click', '#get-sceneObj', function() {
	console.log('sceneObj', sceneObj)
})

$(document).on('click', '#get-materials', function() {
	listMaterials()
})

$(document).on('click', '#get-textures', function() {
	listTextures()
})

function listMaterials() {
	sceneObj.sketchfabAPI.getMaterialList(function(err, materials) {
		if (!err) {
			console.log('materials', materials)
		}
	})
}
function listTextures() {
	sceneObj.sketchfabAPI.getTextureList(function(err, textures) {
		console.log('textures', textures)
	})
}