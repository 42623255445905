import { sceneObj } from './global_vars.js'
import { updateMenu, hideShowMenu } from './global.js'
import { updateTick } from './textures_and_textures_loading.js'
import { menuHeaderColor, removeUnderScoreMenu, isInvalid} from './global_popular_functions.js'
import { setURL } from './url.js'
import { loadDefaultTextures, findMaterialPosition, updateMaterial, getTextures } from './textures_loading.js'
import { updateTexture } from './textures_update.js'
import { editMode } from './edit.js'
import { addOptionHead } from './options.js'
import { addStuds } from './studs.js'
import { addDownloadMenu } from './download_plus.js'
import { addCart } from './cart.js'
import { stitchPattern } from './stitch_pattern.js'
import { highlightUpdateMaterial } from './highlight'



export function startTextures() {
	loadDefaultTextures()
	buildMenuTextures()
}

function buildMenuTextures() {
	for (let i = 0; i < sceneObj.myMaterialsArray.length; i++) {
		$('.configurator').append('<div id=' + sceneObj.myMaterialsArray[i] + ' >' +
		'<div id="texture-menu-head-' + i + '" class="menu-head texture-menu-head" name="' + i + '">' +
		removeUnderScoreMenu(sceneObj.myMaterialsArray[i]) +
		'<img class="menu-image" src="' + sceneObj.texturesFolder + 'textures/thumb/none.png">' +
		'<div class="menu-text"></div>' +
		'</div>' +
		'<div id="buttons-' + i + '" class="buttons"></div>' +
		'</div>')
	}
	$('.menu').css('display', 'flex')
	addStuds()
	addOptionHead()
	addDownloadMenu()
	addCart()
	editMode()
}

$(document).on('click', '.texture-menu-head', function() {
	let index = $(this).attr('name')
	let name = $(this).parent().attr('id')
		highlightUpdateMaterial(index,name)
})


$(document).on('click', '.thumb', function() {
	// Hide menu on small screens
	if (sceneObj.windowWidth < 700) {
		hideShowMenu()
	}
	$('.loader').css('visibility','visible')
	sceneObj.activeTexture = $(this).attr('id')
	if (sceneObj.activeTexture == 'match') {
		sceneObj.activeTexture = sceneObj.myTexturesArray[0]
	}
	updateTexturesArray(sceneObj.activeTexture, sceneObj.activeMaterial)
	stitchPattern()
	updateTick()
	updateMenu()
	setURL()
	updateTexture(sceneObj.activeTexture, sceneObj.activeMaterial)

	for (let z = 0; z < sceneObj.hideObj.length; z++) {
		if ((sceneObj.activeMaterial == sceneObj.hideObj[z][0]) && (sceneObj.activeTexture == sceneObj.hideObj[z][1])) {
			hideMaterial(sceneObj.hideObj[z][0])
		}
		if ((sceneObj.activeMaterial == sceneObj.hideObj[z][0]) && (sceneObj.activeTexture != sceneObj.hideObj[z][1])) {
			showMaterial(sceneObj.hideObj[z][0])
		}
	}
})

// We have a thumb in the menu that is different :)
// Its used to turn on off, not to drape a texture
function hideMaterial(materialName) {
	// Turn off
	for (const key of Object.keys(sceneObj.myNodes)) {
		let name = sceneObj.myNodes[key].name
		if (name != undefined) {
			if (name.includes(materialName)) {
				sceneObj.sketchfabAPI.hide(key, function(err) {
					if (!err) {
						// console.log('Hide node', key) // 114
					}
				})
			}
		}
	}
}
function showMaterial(materialName) {
	// Turn on
	for (const key of Object.keys(sceneObj.myNodes)) {
		let name = sceneObj.myNodes[key].name
		if (name != undefined) {
			if (name.includes(materialName)) {
				sceneObj.sketchfabAPI.show(key, function(err) {
					if (!err) {
						// console.log('Hide node', key) // 114
					}
				})
			}
		}
	}
}


// Update the array myTexturesArray with the selected texture
function updateTexturesArray(texture, material) {
	let index = sceneObj.myMaterialsArray.indexOf(material)
	sceneObj.myTexturesArray.splice(index, 1, texture)
}