import { sceneObj } from './global_vars.js'
import { eraseIllustrations } from './draw_rectangle.js'

export function menuHeaderColor() {
	$('.menu-head').removeClass('menu-active')
	$('.buttons').removeClass('buttons-active')
	$('#' + sceneObj.activeMaterial).children('.menu-head').addClass('menu-active')
	$('#' + sceneObj.activeMaterial).children('.buttons').addClass('buttons-active')
	eraseIllustrations()
}

export function formatTextTitle(str) {
	let string = removeUnderScore(str)
	string = removeHyphen(string)
	return toTitleCase(string)
}

export function toTitleCase(str) {
	return str.replace(/(?:^|\s)\w/g, function(match) {
		return match.toUpperCase()
	})
}

export function removeHyphen(str) {
	return str.replace(/-/g, ' ')
}

export function removeUnderScore(str) {
	return str.replace(/_/g, ' ')
}

export function removeUnderScoreMenu(str) {
	if (str == 'stitching_assembly') {
		str = 'assembly_stitching'
		return str.replace(/_/g, ' ')
	}else {
		return str.replace(/_/g, ' ')
	}
}

export function includesSubstringCaseInsensitive(mainString, substring) {
  return mainString.toLowerCase().includes(substring.toLowerCase());
}
// Example usage:
// const mainString = "Hello, world!";
// const substring = "WORLD";
// const result = includesSubstringCaseInsensitive(mainString, substring);

// console.log(result); // Output: true

export function isInvalid(value) {
  return value === undefined || value === null || Number.isNaN(value) || value === false || value === 0 || value === "" || value === "undefined" || value === "null";
}
// returns true if the value is invalid == ''
// returns false if you have a value