import { sceneObj } from './global_vars.js'
import { menuHeaderColor } from './global_popular_functions.js'


export function getOptionNotes() {
	$('#special-note').remove()
	sceneObj.optionNote = ''
	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
				if (typeof sceneObj.myOptionsArray[i].optionNote !== 'undefined' && sceneObj.myOptionsArray[i].optionNote) {
					sceneObj.optionNote  += sceneObj.myOptionsArray[i].optionNote + '  '
			}
		}
	}
	if (typeof sceneObj.optionNote !== 'undefined' && sceneObj.optionNote) {
		// It's not undefined, null or NaN: Got Note
		$('.configurator').append('<div id="special-note">' +
			'<div id="special-note-menu-head" class="menu-head" name="special-note">' +
				'Special Note' +
			'</div>' +
			'<div id="buttons-special-note" class="buttons">' +
			'</div>' +
		'</div>')
		$("#cart .menu-head").css("border-bottom", "1px solid #ffffff8a");
		$("#special-note-menu-head").css("border-bottom", "none");
	}else {
		// It's undefined, null or NaN: No Note
		$("#cart .menu-head").css("border-bottom", "none");
	}
}

$(document).on('click', '#special-note-menu-head', function() {
	$('.buttons').empty()
	getOptionNotes()
	sceneObj.activeMaterial = $(this).parent().attr('id')
	$('#buttons-special-note').append('<div>' +
		'<div class="option-box-title">' +
			//'<label for="html">'+sceneObj.partGroup.toUpperCase()+'</label>' +
		'</div>' +
		'<div class="option-box-info">' +
			'<p class="option-info-note">'+sceneObj.optionNote+'</p>' +
		'</div>')
	menuHeaderColor()
})